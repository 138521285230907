import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { notification } from 'antd';
import useActiveWorkspaceLoading from '../../hooks/useActiveWorkspaceLoading';
import styles from './index.module.scss';
import useActiveWorkspace from '../../hooks/useActiveWorkspace';

export default function WorkspacePageWrapper() {
  const loading = useActiveWorkspaceLoading();
  const workspace = useActiveWorkspace();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspace) {
      const billingUrl = `/${workspace.slug}/settings/billing`;
      if (!workspace.hasTrialOrActiveSubscription && !location.pathname.startsWith(billingUrl)) {
        notification.error({
          message: 'No active subscription',
          description: 'Please subscribe to a plan to continue using Zupport.',
        });
        navigate(billingUrl);
      }
    }
  }, [location, workspace]);

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <img className={styles.loader} src="/zupport-blue-logo.png" alt="zupport-logo" />
        <h3>
          Loading your workspace
        </h3>
      </div>
    );
  }

  return (<Outlet />);
}
