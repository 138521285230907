import { Card, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { FeatureRequest } from '../../../api';
import { timeAgo } from '../../../common/utils/dates';
import { plural } from '../../../common/utils/strings';
import useRandomNumber from '../../../common/hooks/useRandomNumber';
import FeatureRequestStatusLabel from '../FeatureRequestStatusLabel';

interface FeatureRequestPreviewCardProps extends Pick<FeatureRequest, | 'title' | 'createdAt' | 'votes' | 'status'> {
  url: string;
  className?: string;
}

export default function FeatureRequestPreviewCard(props: FeatureRequestPreviewCardProps) {
  const {
    url,
    title,
    createdAt,
    votes,
    className,
    status,
  } = props;

  return (
    <Link to={url}>
      <Card size="small" className={className}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex align-items-center gap-3">
              <h3 className="m-0">{title}</h3>
              <FeatureRequestStatusLabel status={status} />
            </div>
            <p className="m-0">
              {
                timeAgo(new Date(createdAt))
              }
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-4">
            <div>
              <h2 className="text-center m-0">
                {votes}
              </h2>
              <p
                className="text-center m-0 fw-bold"
                style={{ opacity: 0.80 }}
              >
                {plural(votes, 'Vote')}
              </p>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
}

export function FeatureRequestPreviewCardSkeleton() {
  const titleWidth = useRandomNumber(100, 300);
  const dateWidth = useRandomNumber(50, 150);

  return (
    <Card size="small">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="m-0">
            <Skeleton.Button
              active
              style={{ height: 24, width: titleWidth }}
            />
          </h3>
          <p className="m-0">
            <Skeleton.Button
              active
              style={{ height: 20, marginTop: 2, width: dateWidth }}
            />
          </p>
        </div>
        <div>
          <h2 className="text-center m-0">
            <Skeleton.Button
              active
              style={{ height: 30, width: 20, minWidth: 20 }}
            />
          </h2>
          <p
            className="text-center m-0 fw-bold"
            style={{ opacity: 0.80 }}
          >
            Votes
          </p>
        </div>
      </div>
    </Card>
  );
}
