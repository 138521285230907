import {
  Button, Form, Input, Modal, Switch, Tabs,
} from 'antd';
import { useEffect, useState } from 'react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import WebsiteContentPages from '../components/WebsiteContent/WebsiteContentPages';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import useActiveWorkspace from '../../workspace/hooks/useActiveWorkspace';
import useQuery from '../../common/hooks/useQuery';
import { ContentWebsite } from '../../api/models/ContentWebsite';
import useSize from '../../common/hooks/useSize';
import useActiveWebsite from '../hooks/useActiveWebsite';
import Select from '../../common/components/Select';

export default function WebsiteSettingsContentPage() {
  const [newDomain, setNewDomain] = useState<string | undefined>();
  const [resetDomain, setResetDomain] = useState<string | undefined>();
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const workspace = useActiveWorkspace();
  const website = useActiveWebsite();
  const [form] = Form.useForm();
  const [size, containerRef] = useSize<HTMLDivElement>();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { data: domains, loading, setData } = useQuery<ContentWebsite[]>(
    async () => {
      if (workspace) {
        return api.contentWebsites.list({
          workspaceId: workspace._id,
        });
      }
      return [] as ContentWebsite[];
    },
    [resetDomain, workspace?._id],
  );

  const contentWebsite = domains?.find((item) => item.domain === activeKey);

  const deleteRequest = useRequest(
    async () => {
      if (!contentWebsite) {
        throw new Error('Website not found');
      }

      return api.contentWebsites.delete(contentWebsite._id);
    },
    {
      onSuccess: (result) => {
        setData((prev) => {
          if (!prev) {
            return prev;
          }

          return prev.map((item) => {
            if (item._id === result._id) {
              return result;
            }
            return item;
          });
        });
        setDeleteOpen(false);
      },
    },
  );

  useEffect(() => {
    if (domains?.length === 0) {
      setActiveKey('new');
      setNewDomain('example.com');
    } else {
      setActiveKey(domains?.[0].domain);
    }
  }, [domains]);

  const request = useRequest(
    async (values) => {
      if (!workspace) {
        throw new Error('Workspace not found');
      }

      if (!website) {
        throw new Error('Website not found');
      }

      const resp = await api.contentWebsites.create({
        workspaceId: workspace._id,
        websiteId: website._id,
        ...values,
      });

      setNewDomain(undefined);
      form.resetFields();
      setActiveKey(resp.domain);
      setResetDomain(new Date().toString());
    },
    { form },
  );

  return (
    <>
      <AppSubPageLayoutContent
        title="Content"
        content={(
          <div ref={containerRef}>
            {
              size.width && (
                <Tabs
                  style={{
                    maxWidth: size.width - 10,
                  }}
                  activeKey={activeKey}
                  tabPosition="top"
                  onChange={(key) => setActiveKey(key)}
                  tabBarExtraContent={(
                    <div
                      style={{ minHeight: 46 }}
                      className="d-flex align-items-center d-flex gap-2"
                    >
                      <Button
                        type="default"
                        onClick={() => {
                          setNewDomain('example.com');
                          setActiveKey('new');
                        }}
                        size="small"
                      >
                        Add Website
                      </Button>
                      {
                        !loading
                        && (domains?.length || 0) > 0
                        && activeKey !== 'new'
                        && !contentWebsite?.scheduledForRemovalAt && (
                          <Button
                            type="default"
                            onClick={() => {
                              setDeleteOpen(true);
                            }}
                            size="small"
                            danger
                          >
                            Delete Website
                          </Button>
                        )
                      }
                    </div>
                  )}
                  tabBarStyle={{
                    marginBottom: 0,
                  }}
                  items={[
                    ...(
                      (domains || []).map((item) => ({
                        label: item.domain,
                        key: item.domain,
                        children: (
                          <WebsiteContentPages
                            contentWebsiteId={item._id}
                            scheduledForRemovalAt={item.scheduledForRemovalAt}
                          />
                        ),
                      }))
                    ),
                    ...(
                      newDomain ? [{
                        label: newDomain,
                        key: 'new',
                        children: (
                          <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                              domain: '',
                              enablePageDiscovery: true,
                              includedUrls: [],
                              excludedUrls: [],
                            }}
                            onFinish={request.submit}
                            style={{
                              paddingTop: 10,
                            }}
                          >
                            <Form.Item
                              label="Domain"
                              name="domain"
                              required
                              rules={[
                                {
                                  pattern: /^(localhost|([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$)/,
                                  message: 'Invalid domain',
                                },
                              ]}
                            >
                              <Input
                                placeholder="example.com"
                                onChange={(e) => setNewDomain(e.target.value || 'example.com')}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Include Urls"
                              name="includedUrls"
                              required={false}
                              extra={(
                                <span>
                                  Include only the urls that you want to parse.
                                  Leave empty to parse all urls.
                                  You can use wildcards like
                                  {' '}
                                  <b><code>/about/*</code></b>
                                  {' '}
                                  to match all urls starting with
                                  {' '}
                                  <b><code>/about/</code></b>
                                </span>
                              )}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator: (_, value: string[]) => {
                                    const excludedSubdomains = getFieldValue('excludedUrls');
                                    if (
                                      value
                                      && excludedSubdomains
                                      && value.some((v) => excludedSubdomains.includes(v))
                                    ) {
                                      return Promise.reject(new Error('Urls cannot be in excluded at the same time'));
                                    }

                                    const filterInvalid = value?.filter((v) => !/^\S*/.test(v));
                                    if (value && filterInvalid.length > 0) {
                                      const invalidDomainText = filterInvalid?.length > 1 ? 'Invalid urls' : 'Invalid url';
                                      return Promise.reject(new Error(`${invalidDomainText}: ${filterInvalid.join(', ')}`));
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Select
                                suffixIcon={null}
                                showSearch={false}
                                mode="tags"
                                tokenSeparators={[',', ' ']}
                                options={[]}
                                placeholder="/help/*"
                                popupClassName="d-none"
                              />
                            </Form.Item>
                            <Form.Item
                              label="Exclude Urls"
                              name="excludedUrls"
                              required={false}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator: (_, value: string[]) => {
                                    const includedSubdomains = getFieldValue('includedUrls');
                                    if (
                                      value
                                      && includedSubdomains
                                      && value.some((v) => includedSubdomains.includes(v))
                                    ) {
                                      return Promise.reject(new Error('Urls cannot be in included at the same time'));
                                    }
                                    const filterInvalid = value?.filter((v) => !/^\S*/.test(v));
                                    if (value && filterInvalid.length > 0) {
                                      const invalidDomainText = filterInvalid?.length > 1 ? 'Invalid urls' : 'Invalid url';
                                      return Promise.reject(new Error(`${invalidDomainText}: ${filterInvalid.join(', ')}`));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              extra={(
                                <span>
                                  A list of urls that you want to exclude from parsing.
                                  They have precedence over included urls.
                                  You can use wildcards like
                                  {' '}
                                  <b><code>/about/*</code></b>
                                  {' '}
                                  to match all urls starting with
                                  {' '}
                                  <b><code>/about/</code></b>
                                </span>
                              )}
                            >
                              <Select
                                suffixIcon={null}
                                showSearch={false}
                                mode="tags"
                                tokenSeparators={[',', ' ']}
                                options={[]}
                                placeholder="/about/*"
                                popupClassName="d-none"
                              />
                            </Form.Item>
                            <Form.Item
                              name="enablePageDiscovery"
                              valuePropName="checked"
                              label="Enable Page Discovery (Follow links)"
                              extra={(
                                <span>
                                  When enabled, we will try to find new links even
                                  on all the pages, including the excluded pages.
                                  However, we will not include the content of the excluded pages
                                  in the AI models.
                                  When this option is off, we will not follow links
                                  on excluded pages.
                                </span>
                              )}
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                type="primary"
                                loading={request.loading}
                              >
                                Add Website
                              </Button>
                            </Form.Item>
                          </Form>
                        ),
                      }] : []
                    ),
                  ]}
                />
              )
            }
          </div>
        )}
      />
      <Modal
        open={deleteOpen}
        title="Delete the website"
        okButtonProps={{
          danger: true,
          loading: deleteRequest.loading,
        }}
        okText="Delete Website"
        onOk={deleteRequest.submit}
        onCancel={() => setDeleteOpen(false)}
      >
        <p>
          Are you sure? This will remove all the website content
          from the AI assistants and they will no longer be able
          to answer questions using this website content.
        </p>
        <p>
          You can refresh the website pages content by clicking the refresh
          button in the website pages list.
        </p>
      </Modal>
    </>
  );
}
