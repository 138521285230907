import { UserSegmentRuleAttribute, UserSegmentRuleAttributeCondition } from '../../api';
import UserSegmentRuleAttributeConditionConfig from './UserSegmentRuleAttributeConditionConfig';
import UserSegmentRuleAttributeConfig from './UserSegmentRuleAttributeConfig';

type UserSegmentRuleAttributes = {
  [key in UserSegmentRuleAttribute]: UserSegmentRuleAttributeConfig;
} & {
  [key: string]: UserSegmentRuleAttributeConfig;
};

type UserSegmentRuleAttributeConditions = {
  [key in UserSegmentRuleAttributeCondition]: UserSegmentRuleAttributeConditionConfig;
} & {
  [key: string]: UserSegmentRuleAttributeConditionConfig;
};

export const userSegmentRuleAttributes: UserSegmentRuleAttributes = {
  [UserSegmentRuleAttribute.SIGN_UP_DATE]: {
    label: 'Sign Up Date',
    attribute: UserSegmentRuleAttribute.SIGN_UP_DATE,
    conditions: [
      UserSegmentRuleAttributeCondition.GREATER_THAN,
      UserSegmentRuleAttributeCondition.LESS_THAN,
    ],
    unitSuffix: 'days ago',
  },
  [UserSegmentRuleAttribute.COMPANY_ID]: {
    label: 'Company ID',
    attribute: UserSegmentRuleAttribute.COMPANY_ID,
    conditions: [
      UserSegmentRuleAttributeCondition.IN,
    ],
  },
  [UserSegmentRuleAttribute.COMPANY_NAME]: {
    label: 'Company Name',
    attribute: UserSegmentRuleAttribute.COMPANY_NAME,
    conditions: [
      UserSegmentRuleAttributeCondition.IN,
    ],
  },
  [UserSegmentRuleAttribute.COMPANY_MRR]: {
    label: 'Company Monthly Recurring Revenue',
    attribute: UserSegmentRuleAttribute.COMPANY_MRR,
    conditions: [
      UserSegmentRuleAttributeCondition.GREATER_THAN,
      UserSegmentRuleAttributeCondition.LESS_THAN,
    ],
  },
  [UserSegmentRuleAttribute.COMPANY_SIGN_UP_DATE]: {
    label: 'Company Sign Up Date',
    attribute: UserSegmentRuleAttribute.COMPANY_SIGN_UP_DATE,
    conditions: [
      UserSegmentRuleAttributeCondition.GREATER_THAN,
      UserSegmentRuleAttributeCondition.LESS_THAN,
    ],
    unitSuffix: 'days ago',
  },
  [UserSegmentRuleAttribute.COMPANY_SUBSCRIBE_DATE]: {
    label: 'Company Subscribe Date',
    attribute: UserSegmentRuleAttribute.COMPANY_SUBSCRIBE_DATE,
    conditions: [
      UserSegmentRuleAttributeCondition.GREATER_THAN,
      UserSegmentRuleAttributeCondition.LESS_THAN,
    ],
    unitSuffix: 'days',
  },
};

export const userSegmentRuleAttributeConditions: UserSegmentRuleAttributeConditions = {
  [UserSegmentRuleAttributeCondition.GREATER_THAN]: {
    label: 'Is more than',
    condition: UserSegmentRuleAttributeCondition.GREATER_THAN,
  },
  [UserSegmentRuleAttributeCondition.LESS_THAN]: {
    label: 'Is less than',
    condition: UserSegmentRuleAttributeCondition.LESS_THAN,
  },
  [UserSegmentRuleAttributeCondition.IN]: {
    label: 'Is one of',
    condition: UserSegmentRuleAttributeCondition.IN,
  },
};
