import BaseService from './BaseService';
import Paginated from '../types/Paginated';
import Workspace from '../models/Workspace';
import WorkspaceUser, { WorkspaceUserData } from '../models/WorkspaceUser';

export default class Workspaces extends BaseService {
  async my(): Promise<Paginated<Workspace>> {
    return this._get('/workspaces/my');
  }

  async get(id: string): Promise<Workspace> {
    return this._get(`/workspaces/${id}`);
  }

  async getBySlug(slug: string): Promise<Workspace> {
    return this._get(`/workspaces/slug/${slug}`);
  }

  async patch(id: string, data: Partial<Pick<Workspace, 'name' | 'slug'>>): Promise<Workspace> {
    return this._patch(`/workspaces/${id}`, data);
  }

  async create(data: Pick<Workspace, 'name' | 'slug'>): Promise<Workspace> {
    return this._post('/workspaces', data);
  }

  users = {
    me: async (workspaceId: string): Promise<WorkspaceUser> => this._get<WorkspaceUser>(`/workspaces/${workspaceId}/users/me`),
    list: async (workspaceId: string): Promise<Paginated<WorkspaceUser>> => this._get(`/workspaces/${workspaceId}/users`),
    patch: async (
      workspaceId: string,
      userId: string,
      data: Pick<WorkspaceUserData, 'role'>,
    ) => this._patch(`/workspaces/${workspaceId}/users/${userId}`, data),
    delete: async (workspaceId: string, userId: string) => this._delete(`/workspaces/${workspaceId}/users/${userId}`),
    invite: {
      send: async (
        workspaceId: string,
        data: WorkspaceUserData,
      ): Promise<WorkspaceUser> => this._post(`/workspaces/${workspaceId}/users`, data),
      get: async (
        workspaceUserId: string,
        token: string,
      ): Promise<WorkspaceUser> => this._get(`/workspaces/users/${workspaceUserId}/invite/${token}`),
      accept: async (
        workspaceUserId: string,
        token: string,
      ): Promise<WorkspaceUser> => this._post(`/workspaces/users/${workspaceUserId}/invite/${token}/accept`),
    },
  };
}
