import { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import api, { FeatureRequestSearchDocument, SearchResult } from '../../api';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';
import Spinner from '../../common/components/Spinner';
import FeatureRequestPreviewCard from '../components/FeatureRequestPreviewCard';
import Select from '../../common/components/Select';
import debounce from '../../common/utils/debounce';

export default function FeatureRequestsPage() {
  const workspaceId = useActiveWorkspaceId();
  const [requests, setRequests] = useState<SearchResult<FeatureRequestSearchDocument>[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    sort: 'popular',
    search: '',
  });

  const onSearchChange = useCallback(debounce((value: string) => {
    setPagination((prev) => ({
      ...prev,
      search: value,
    }));
  }, 500), []);

  useEffect(() => {
    if (workspaceId) {
      setRequests([]);
      setLoading(true);
      api.featureRequests.search({
        $limit: 100,
        workspaceId,
        ...pagination,
      }).then((response) => {
        setRequests(response.items);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [workspaceId, pagination]);

  return (
    <AppSubPageLayoutContent
      title="Feature Requests"
      width="medium"
      content={(
        <div>
          <Input
            placeholder="Search Feature Requests"
            addonAfter={(
              <Select
                options={[
                  {
                    label: 'Most Popular',
                    value: 'popular',
                  },
                  {
                    label: 'Newest First',
                    value: 'newest',
                  },
                  {
                    label: 'Oldest First',
                    value: 'oldest',
                  },
                ]}
                value={pagination.sort}
                onChange={(value) => setPagination((prev) => ({
                  ...prev,
                  sort: value,
                }))}
              />
            )}
            onChange={(e) => onSearchChange(e.target.value)}
            allowClear
          />
          <div className="mt-2">
            {
              loading && <Spinner center />
            }
            {
              requests.map((request) => (
                <FeatureRequestPreviewCard
                  key={request.document._id}
                  url={`../${request.document._id}`}
                  title={request.document.title}
                  createdAt={request.document.createdAt}
                  votes={request.document.votes}
                  status={request.document.status}
                  className="mb-2"
                />
              ))
            }
          </div>
        </div>
      )}
    />
  );
}
