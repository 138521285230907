import { Card, Statistic } from 'antd';
import { Workspace } from '../../../api';
import { BillingCreditType } from '../../../api/models/BillingCredit';

interface UsageInfoProps {
  workspace: Workspace;
}

export default function UsageInfo(props: UsageInfoProps) {
  const { workspace } = props;

  return (
    <Card size="small">
      <h2 className="mt-0">Usage</h2>

      <Statistic
        title="AI Replies Remaining"
        value={workspace.creditsLeft?.[BillingCreditType.AI_REPLY] || 0}
      />
    </Card>
  );
}
