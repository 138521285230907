import { useCallback, useEffect, useState } from 'react';

interface UseQueryState<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}

interface UseQuery<T> extends UseQueryState<T> {
  setData: (data: T | ((prev: T | null) => T | null)) => void;
}

export default function useQuery<T>(
  getter: () => Promise<T>,
  deps: any[] = [],
  defaultValue?: T,
  enabled = true,
): UseQuery<T> {
  const [state, setState] = useState<UseQueryState<T>>({
    data: defaultValue || null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (enabled) {
      setState((prev) => ({ ...prev, loading: true }));
      getter()
        .then((res) => {
          setState({ data: res, loading: false, error: null });
        })
        .catch((error) => {
          setState({ data: null, loading: false, error });
        });
    }
  }, [...deps, enabled]);

  const setData = useCallback((data: T | ((prev: T | null) => T | null)) => {
    setState((prev) => ({
      ...prev,
      // @ts-ignore
      data: typeof data === 'function' ? data(prev.data) as T : data,
    }));
  }, []);

  return {
    ...state,
    setData,
  };
}
