import { Card, Tag } from 'antd';
import { Fragment, useState } from 'react';
import { UserSegment } from '../../api';
import { userSegmentRuleAttributeConditions, userSegmentRuleAttributes } from '../logic/config';
import Button from '../../common/components/Button';
import UserSegmentConstructor from './UserSegmentConstructor';

interface UserSegmentCardProps {
  segment: UserSegment;
  onChange?: (segment: UserSegment) => void;
}

export default function UserSegmentCard(props: UserSegmentCardProps) {
  const { segment, onChange } = props;
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  return (
    <Card key={segment._id} size="small" className="mb-2">
      <div className="d-flex justify-content-between">
        <h3 className="mt-0">
          {segment.name}
        </h3>
        {
          mode === 'view' && (
            <Button
              type="default"
              size="small"
              onClick={() => {
                setMode('edit');
              }}
            >
              Edit
            </Button>
          )
        }
      </div>
      {
        mode === 'edit' && (
          <UserSegmentConstructor
            segment={segment}
            onCancel={() => {
              setMode('view');
            }}
            onSuccess={(result) => {
              if (onChange) {
                onChange(result);
              }

              setMode('view');
            }}
          />
        )
      }
      {
        mode === 'view' && (
          <>
            {
              segment.definition.rules.length === 0 && (
                <p className="m-0">
                  No rules defined.
                </p>
              )
            }
            {
              segment.definition.rules.length > 0 && (
                <div>
                  {
                    segment.definition.rules.map((rule, index) => {
                      const attribute = userSegmentRuleAttributes[rule.attribute];
                      const key = String(index);

                      if (!attribute) {
                        return null;
                      }

                      const condition = userSegmentRuleAttributeConditions[rule.condition];

                      return (
                        <Fragment key={key}>
                          {
                            index > 0 && (
                              <p className="text-uppercase">
                                {segment.definition.operator}
                              </p>
                            )
                          }
                          <span>
                            <Tag color="blue" bordered={false}>
                              {attribute.label}
                            </Tag>
                            <Tag color="blue" bordered={false}>
                              {condition.label}
                            </Tag>
                            <Tag color="blue">
                              {rule.value}
                              {
                          attribute.unitSuffix ? ` ${attribute.unitSuffix}` : ''
                        }
                            </Tag>
                          </span>
                        </Fragment>
                      );
                    })
                  }
                </div>
              )
            }
          </>
        )
      }
    </Card>
  );
}
