import { Outlet, useMatch } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import AppSubPageLayout, { AppSubPageLayoutProps } from '../../layout/components/AppSubPageLayout';
import usePermissions from '../../permissions/hooks/usePermissions';
import LightBulbFilamentIcon from '../../common/components/Icons/LightBulbFilamentIcon';

interface FeatureRequestsPageLayoutProps extends Omit<AppSubPageLayoutProps, 'pages' | 'activePageKey' | 'content'> {}

export default function FeatureRequestsPageLayout(props: FeatureRequestsPageLayoutProps) {
  const match = useMatch('/:workspaceSlug/feature-requests/:pageKey?');
  const activePageKey = match?.params.pageKey || 'feature-requests';
  const permissions = usePermissions();

  return (
    <AppSubPageLayout
      allowed={permissions.featureRequests.manage}
      activePageKey={activePageKey}
      pages={[
        {
          key: 'feature-requests',
          title: 'Feature Requests',
          path: 'feature-requests',
          icon: <LightBulbFilamentIcon />,
        },
        {
          key: 'settings',
          title: 'Settings',
          path: 'settings',
          icon: <SettingOutlined />,
        },
      ]}
      content={<Outlet />}
      {...props}
    />
  );
}
