import Workspace, { SlackIntegrationOptions } from '../models/Workspace';
import BaseService from './BaseService';
import SlackChannel from '../models/SlackChannel';

export default class Integrations extends BaseService {
  slack = {
    authorize: (data: { code: string; workspaceId: string }): Promise<Workspace> => this._post(
      '/integrations/slack/authorize',
      data,
    ),
    patch: (
      workspaceId: string,
      data: SlackIntegrationOptions,
    ): Promise<Workspace> => this._patch(
      `/integrations/slack/${workspaceId}`,
      data,
    ),
    channels: {
      list: (workspaceId: string): Promise<SlackChannel[]> => this._get('/integrations/slack/channels', {
        params: {
          workspaceId,
        },
      }),
    },
  };
}
