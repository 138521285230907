// eslint-disable-next-line import/prefer-default-export
export const REACT_APP_API_URL: string = process.env.REACT_APP_API_URL || '';
export const REACT_APP_ZUPPORT_WEBSITE_UID: string = process.env.REACT_APP_ZUPPORT_WEBSITE_UID || '';
export const REACT_APP_CDN_URL: string = process.env.REACT_APP_CDN_URL || '';
export const REACT_APP_WIDGETS_APP_URL = process.env.REACT_APP_WIDGETS_APP_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const REQUIRED_ENV_VARS: Record<string, any> = {
  REACT_APP_API_URL,
  REACT_APP_CDN_URL,
  REACT_APP_ZUPPORT_WEBSITE_UID,
  REACT_APP_WIDGETS_APP_URL,
};

Object.entries(REQUIRED_ENV_VARS).forEach(([key, value]) => {
  if (!value) {
    throw new Error(`Missing required environment variable: ${key}`);
  }
});
