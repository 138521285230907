import { Store } from 'pullstate';
import { SlackChannel } from '../../api';

type IntegrationsState = {
  slack: {
    loading: boolean;
    channels: SlackChannel[];
    initialized: boolean;
  };
};

// eslint-disable-next-line import/prefer-default-export
export const IntegrationsStore = new Store<IntegrationsState>({
  slack: {
    loading: false,
    channels: [],
    initialized: false,
  },
});
