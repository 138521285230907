// eslint-disable-next-line import/prefer-default-export
export function deepMerge<T>(target: T, source: T): T {
  const output = { ...target };

  if (!target) {
    return source;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in source) {
    if (source[key] instanceof Object) {
      // @ts-ignore
      Object.assign(output, { [key]: deepMerge(target[key], source[key]) });
    } else {
      // @ts-ignore
      Object.assign(output, { [key]: source[key] });
    }
  }

  return output;
}
