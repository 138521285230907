import { Menu } from 'antd';
import { InboxOutlined, QuestionOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import BrainIcon from '../../../common/components/Icons/BrainIcon';
import useURLBuilder from '../../../common/hooks/useURLBuilder';
import { InboxPageParams } from '../../config/params';
import AppPageTitle from '../../../layout/components/AppPageTitle';
import AppPageVerticalSection from '../../../layout/components/AppPageVerticalSection';

interface InboxPageSidebarProps {
  onItemClick?: () => void;
}

export default function InboxPageSidebar(props: InboxPageSidebarProps) {
  const { onItemClick } = props;

  const urlBuilder = useURLBuilder();
  const [params] = useSearchParams();
  const tab = params.get(InboxPageParams.TAB) || 'inbox';

  return (
    <AppPageVerticalSection header={<AppPageTitle title="Inbox" />}>
      <Menu
        selectedKeys={[tab]}
        items={[
          {
            key: 'inbox',
            label: (
              <Link to={urlBuilder({ [InboxPageParams.TAB]: 'inbox' })}>
                Your Conversations
              </Link>
            ),
            icon: <InboxOutlined />,
            onClick: onItemClick,
          },
          {
            key: 'ai',
            label: (
              <Link to={urlBuilder({ [InboxPageParams.TAB]: 'ai' })}>
                AI Conversations
              </Link>
            ),
            icon: <BrainIcon />,
            onClick: onItemClick,

          },
          {
            key: 'unassigned',
            label: (
              <Link to={urlBuilder({ [InboxPageParams.TAB]: 'unassigned' })}>
                Unassigned
              </Link>
            ),
            icon: <QuestionOutlined />,
            onClick: onItemClick,

          },
          {
            key: 'all',
            label: (
              <Link to={urlBuilder({ [InboxPageParams.TAB]: 'all' })}>
                All Conversations
              </Link>
            ),
            icon: <UnorderedListOutlined />,
            onClick: onItemClick,
          },
        ]}
      />
    </AppPageVerticalSection>
  );
}
