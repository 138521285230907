export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function plural(value: number, singular: string, pluralWord = `${singular}s`) {
  return value === 1 ? singular : pluralWord;
}

export function pluralize(count: number, word: string) {
  return `${count} ${plural(count, word)}`;
}
