import { Col, Row } from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import WorkspaceSlackIntegrationCard from '../components/WorkspaceSlackIntegrationCard';

export default function WorkspaceIntegrationsPage() {
  return (
    <AppSubPageLayoutContent
      title="Integrations"
      width="medium"
      content={(
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <WorkspaceSlackIntegrationCard />
          </Col>
        </Row>
      )}
    />
  );
}
