import { Store } from 'pullstate';
import { UserSegment } from '../../api';

type UserSegmentsState = {
  initialized: boolean;
  segments: UserSegment[];
};

// eslint-disable-next-line import/prefer-default-export
export const UserSegmentsStore = new Store<UserSegmentsState>({
  initialized: false,
  segments: [],
});
