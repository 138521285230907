import MessengerWidgetConfiguration, { MessengerWidgetConfigurationData } from '@zupport/types/models/MessengerWidgetConfiguration';
import defaultMessengerWidgetConfig from '@zupport/config/ui/default-messenger-widget-config';
import Paginated from '@zupport/types/types/Paginated';
import Query from '@zupport/types/types/Query';
import BaseService from './BaseService';

export default class MessengerWidgetConfigurations extends BaseService {
  async create(data: Pick<MessengerWidgetConfigurationData, 'websiteId' | 'workspaceId'>) {
    return this._post<MessengerWidgetConfigurationData, MessengerWidgetConfiguration>(
      '/messenger/widget/configurations',
      {
        name: 'Untitled',
        enabled: false,
        userSegments: [],
        ...data,
        ...defaultMessengerWidgetConfig,
      },
    );
  }

  async get(id: string) {
    return this._get<MessengerWidgetConfiguration>(`/messenger/widget/configurations/${id}`);
  }

  async patch(id: string, data: Partial<MessengerWidgetConfigurationData>) {
    return this._patch<Partial<MessengerWidgetConfigurationData>, MessengerWidgetConfiguration>(
      `/messenger/widget/configurations/${id}`,
      data,
    );
  }

  async list(params?: Query<Pick<MessengerWidgetConfiguration, 'websiteId'>>) {
    return this._get<Paginated<MessengerWidgetConfiguration>>('/messenger/widget/configurations', { params });
  }

  async delete(id: string) {
    return this._delete<MessengerWidgetConfiguration>(`/messenger/widget/configurations/${id}`);
  }

  async patchMultimedia(
    id: string,
    data: Partial<{ logoLight: File; logoDark: File }>,
  ) {
    const formData = new FormData();
    if (data.logoLight) {
      formData.append('logoLight', data.logoLight);
    }
    if (data.logoDark) {
      formData.append('logoDark', data.logoDark);
    }

    return this._patch<FormData, MessengerWidgetConfiguration>(
      `/messenger/widget/configurations/${id}/multimedia`,
      formData,
    );
  }
}
