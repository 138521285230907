import { useCallback, useEffect, useState } from 'react';
import {
  Card, Divider, Input, List,
} from 'antd';
import SimpleBar from 'simplebar-react';
import WebsiteContentPageItem from './WebsiteContentPageItem';
import api, { ContentWebsitePage, ContentWebsitePageCrawlingStatus } from '../../../api';
import useActiveWorkspace from '../../../workspace/hooks/useActiveWorkspace';
import Button from '../../../common/components/Button';
import { ContentWebsite } from '../../../api/models/ContentWebsite';
import Select, { SelectOption } from '../../../common/components/Select';
import { ContentWebsitePageCrawlingStatusLabels } from '../WebsiteStatusTag';
import useSearchParam from '../../../common/hooks/useSearchParam';
import debounce from '../../../common/utils/debounce';

const $limit = 20;

interface WebsiteContentPagesProps {
  contentWebsiteId: string;
  scheduledForRemovalAt?: ContentWebsite['scheduledForRemovalAt'];
}

const statusOptions: SelectOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  ...Object.values(ContentWebsitePageCrawlingStatus).map((status) => ({
    label: ContentWebsitePageCrawlingStatusLabels[status],
    value: status,
  })),
];

export default function WebsiteContentPages(props: WebsiteContentPagesProps) {
  const { contentWebsiteId, scheduledForRemovalAt } = props;

  const [status, setStatus] = useSearchParam('status', ContentWebsitePageCrawlingStatus.SUCCESS);
  const activeWorkspace = useActiveWorkspace();
  const [pages, setPages] = useState<ContentWebsitePage[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    status,
    $search: '',
  });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
      status,
    }));
  }, [status]);

  const search = useCallback(debounce((value: string) => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
      $search: value,
    }));
  }, 500), []);

  useEffect(() => {
    if (activeWorkspace && pagination.page > 0) {
      setLoading(true);
      api.contentWebsites.pages.list({
        workspaceId: activeWorkspace._id,
        contentWebsiteId,
        status: status !== 'all' ? (status as ContentWebsitePageCrawlingStatus) : undefined,
        $limit,
        $skip: (pagination.page - 1) * $limit,
        $search: pagination.$search,
      }).then((res) => {
        setPages((prev) => [
          ...(pagination.page === 1 ? [] : prev),
          ...res.items,
        ]);
        setHasMore(res.items.length >= $limit);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pagination, activeWorkspace?._id]);

  if (scheduledForRemovalAt) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <h3 className="text-center">This website is scheduled for deletion</h3>
      </div>
    );
  }

  return (
    <Card
      size="small"
      title={(
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="m-0">
            Pages
          </h3>
          <div className="d-flex align-items-center gap-2">
            <Input
              size="small"
              allowClear
              placeholder="Search by URL"
              onChange={(e) => { search(e.target.value); }}
            />
            <Select
              options={statusOptions}
              size="small"
              style={{ minWidth: 120 }}
              value={status}
              onChange={setStatus}
            />
          </div>
        </div>
      )}
      style={{
        marginTop: 10,
        paddingBottom: 0,
      }}
      styles={{
        body: {
          paddingBottom: 0,
        },
      }}
    >
      <SimpleBar
        style={{
          maxHeight: 'calc(var(--app-sub-page-layout-content-with-tabs-height) - 76px)',
          overflow: 'auto',
          paddingTop: 10,
        }}
      >
        <List
          dataSource={pages}
          loading={loading}
          loadMore={hasMore && (!loading || pagination.page > 1) && (
            <div className="d-flex justify-content-center">
              <Button
                style={{
                  marginBottom: 12,
                }}
                loading={loading}
                onClick={() => setPagination((prev) => ({ ...prev, page: prev.page + 1 }))}
              >
                Load more
              </Button>
            </div>
          )}
          renderItem={(item, index) => (
            <>
              <WebsiteContentPageItem
                key={`page-${item._id}`}
                id={item._id}
                url={item.url}
                title={item.title}
                status={item.status}
                lastCrawledAt={item.lastCrawledAt}
              />
              {
                index < pages.length - 1 && (
                  <Divider
                    variant="solid"
                    style={{ backgroundColor: 'var(--app-content-separator-border-color)', margin: 0 }}
                  />
                )
              }
            </>
          )}
        />
      </SimpleBar>
    </Card>
  );
}
