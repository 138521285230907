import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { List, Radio } from 'antd';
import SimpleBar from 'simplebar-react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';
import useActiveHelpCenter from '../hooks/useActiveHelpCenter';
import useQuery from '../../common/hooks/useQuery';
import api, { HelpCenterArticle, HelpCenterArticleStatus, Paginated } from '../../api';
import HelpCenterArticleSelect, { useHelpCenterCategories } from '../components/HelpCenterArticleSelect';
import useURLBuilder from '../../common/hooks/useURLBuilder';
import Divider from '../../common/components/Divider';
import Select from '../../common/components/Select';
import { getDayDisplay } from '../../common/utils/dates';

interface HelpCenterArticleWithCategory extends HelpCenterArticle {
  category?: HelpCenterArticle;
}

export default function HelpCenterArticlesPage() {
  const helpCenter = useActiveHelpCenter();
  const categories = useHelpCenterCategories(helpCenter?._id, helpCenter?.config.languages.default);
  const buildURL = useURLBuilder();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const categoryId = params.get('category') || undefined;
  const type = params.get('type') || 'articles';
  const status = params.get('status') || null;

  const isCategory = type === 'categories';

  const query = useQuery<Paginated<HelpCenterArticle> | undefined>(
    async () => {
      if (helpCenter) {
        return api.helpCenters.articles.list({
          helpCenterId: helpCenter._id,
          isCategory,
          parentId: categoryId,
          status: status as HelpCenterArticle['status'],
          // TODO: add pagination
          $limit: 200,
          $project: {
            _id: 1,
            parentId: 1,
            isCategory: 1,
            publishedAt: 1,
            status: 1,
            [`content.${helpCenter.config.languages.default}.title`]: 1,
          },
        });
      }

      return undefined;
    },
    [helpCenter?._id, categoryId, isCategory, status],
  );

  const items: HelpCenterArticleWithCategory[] = (query.data?.items || []).map((item) => ({
    ...item,
    category: categories.find((category) => category._id === item.parentId),
  }));

  return (
    <AppSubPageLayoutContent
      title="Articles"
      content={helpCenter && (
        <div>
          <div className="mb-2 d-flex flex-column flex-md-row gap-2 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              {
                !isCategory && (
                  <HelpCenterArticleSelect
                    helpCenterId={helpCenter._id}
                    defaultLanguage={helpCenter.config.languages.default}
                    size="small"
                    style={{ minWidth: 200 }}
                    value={categoryId}
                    onChange={(value) => {
                      navigate(buildURL({ category: value }));
                    }}
                    isCategory
                  />
                )
              }
              <Select
                placeholder="Status"
                size="small"
                style={{ minWidth: 100 }}
                options={[
                  { label: 'All', value: null },
                  { label: 'Draft', value: 'draft' },
                  { label: 'Published', value: 'published' },
                ]}
                value={status}
                onChange={(value) => {
                  navigate(buildURL({ status: value }));
                }}
              />
            </div>
            <Radio.Group
              options={[
                { label: 'Articles', value: false },
                { label: 'Categories', value: true },
              ]}
              optionType="button"
              buttonStyle="solid"
              value={isCategory}
              onChange={(e) => {
                navigate(buildURL({ type: e.target.value ? 'categories' : 'articles' }));
              }}
            />
          </div>
          <SimpleBar
            style={{
              maxHeight: 'calc(var(--app-sub-page-layout-content-with-tabs-height) - 4px)',
              overflow: 'auto',
              paddingTop: 10,
            }}
          >
            <List
              bordered
              dataSource={items}
              loading={query.loading}
              renderItem={(item, index) => {
                let title = item.content[helpCenter.config.languages.default]?.title || 'Untitled';

                if (item.status === HelpCenterArticleStatus.DRAFT) {
                  title = `[DRAFT] ${title}`;
                }

                return (
                  <>
                    {
                      index > 0 && (
                        <Divider className="m-0" />
                      )
                    }
                    <Link
                      to={item._id}
                      key={item._id}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <List.Item
                        actions={[
                          (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <div className="d-flex flex-column align-items-end">
                              {
                                !item.isCategory && (
                                  item.category ? (
                                    <Link to={item.category._id}>
                                      {
                                        item
                                          .category
                                          .content[helpCenter.config.languages.default]?.title
                                      }
                                    </Link>
                                  ) : 'No category selected'
                                )
                              }
                              <span>
                                {getDayDisplay(new Date(item.publishedAt))}
                              </span>
                            </div>
                          ),
                        ]}
                      >
                        <List.Item.Meta title={title} />
                      </List.Item>
                    </Link>
                  </>
                );
              }}
            />
          </SimpleBar>
        </div>
      )}
      titleAction={(
        <Link to="create">
          <Button icon={<PlusOutlined />}>Create Article</Button>
        </Link>
      )}
    />
  );
}
