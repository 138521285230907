import BaseModel from './BaseModel';

export enum BillingCreditType {
  AI_REPLY = 'ai-reply',
}

export enum BillingCreditSource {
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_QUANTITY_CHANGE = 'subscription-quantity-change',
  SUBSCRIPTION_RENEW = 'subscription-renew',
  REPLY_DEDUCTION = 'reply-deduction',
  FREE_TRIAL = 'free-trial',
}

export default interface BillingCredit extends BaseModel {
  /**
   * The type of the credits
   */
  type: BillingCreditType;

  /**
   * The total amount of credits
   * It can be a positive number, when there is a refill
   * It can be a negative number, when there is a deduction
   */
  amount: number;

  /**
   * The source of the credits
   */
  source: BillingCreditSource;

  /**
   * The workspace id
   */
  workspaceId: BaseModel['_id'];

  /**
   * The conversation id that the credits are related to
   * (for deductions only)
   */
  conversationId?: BaseModel['_id'];

  /**
   * The date when the credits are expiring
   */
  expiryDate: Date;
}
