import Select, { SelectProps } from '../../common/components/Select';
import useUserSegments from '../hooks/useUserSegments';

export default function UserSegmentSelect(props: Omit<SelectProps, 'options'>) {
  const segments = useUserSegments();

  return (
    <Select
      {...props}
      options={segments.map((segment) => ({
        value: segment._id,
        label: segment.name,
      }))}
    />
  );
}
