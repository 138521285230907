import BaseModel from './BaseModel';
import { BillingCreditType } from './BillingCredit';
import Subscription from './Subscription';

export enum SlackStreamMode {
  INITIAL = 'initial',
  ONE_WAY = 'one-way',
  TWO_WAY = 'two-way',
}

export interface SlackStream {
  channelId: string;
  segmentId?: string;
  mode: SlackStreamMode;
}

export interface SlackIntegrationOptions {
  enabled?: boolean;
  streams?: SlackStream[];
}

export default interface Workspace extends BaseModel {
  name: string;

  // Slug
  slug: string;

  // Website
  websiteId: BaseModel['_id'];

  // Subscription data
  subscription?: Subscription;

  // Based on the subscription status
  hasTrialOrActiveSubscription: boolean;

  // Based on the subscription status
  inTrial: boolean;

  // Based on the subscription plan
  membersLimit: number;

  // Based on the subscription plan
  stripeCustomerId: string;

  // Based on the subscription plan
  activePlanId?: string;

  // Monthly Recurring Revenue
  mrr?: number;

  integrations?: {
    slack?: SlackIntegrationOptions;
  }

  creditsLeft?: { [key in BillingCreditType]?: number };
}
