import BaseService from './BaseService';
import UserSegment, { UserSegmentData } from '../models/UserSegment';
import Paginated from '../types/Paginated';

export default class UserSegments extends BaseService {
  async create(data: UserSegmentData): Promise<UserSegment> {
    return this._post('/user-segments', data);
  }

  async list(params: Pick<UserSegment, 'workspaceId' | 'websiteId'>): Promise<Paginated<UserSegment>> {
    return this._get('/user-segments', { params });
  }

  async patch(id: UserSegment['_id'], data: Partial<UserSegmentData>): Promise<UserSegment> {
    return this._patch(`/user-segments/${id}`, data);
  }
}
