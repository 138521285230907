import BaseModel from './BaseModel';

export enum UserSegmentRuleAttribute {
  SIGN_UP_DATE = 'signUpDate',
  COMPANY_MRR = 'company.mrr',
  COMPANY_ID = 'company.id',
  COMPANY_NAME = 'company.name',
  COMPANY_SIGN_UP_DATE = 'company.signUpDate',
  COMPANY_SUBSCRIBE_DATE = 'company.subscribeDate',
}

export enum UserSegmentRuleAttributeCondition {
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  IN = 'in',
}

export interface UserSegmentRule {
  /**
   * The attribute that the rule is based on
   */
  attribute: UserSegmentRuleAttribute;

  /**
   * The condition that the rule is based on
  */
  condition: UserSegmentRuleAttributeCondition;

  /**
   * The value for the condition
   */
  value: any;
}

export enum UserSegmentGroupOperator {
  OR = 'or',
  AND = 'and',
}

export interface UserSegmentRuleGroup {
  /**
   * The operator for the group
   */
  operator: UserSegmentGroupOperator;

  /**
   * A nested group of rules
   */
  group?: UserSegmentRuleGroup;

  /**
   * A list of rules
   */
  rules: UserSegmentRule[];
}

export interface UserSegmentData {
  /**
   * User segment name
   */
  name: string;

  /**
   * Definition
  */
  definition: UserSegmentRuleGroup;

  /**
   * Website ID
   */
  websiteId: BaseModel['_id'];

  /**
   * Workspace ID
   */
  workspaceId: BaseModel['_id'];
}

export default interface UserSegment extends UserSegmentData, BaseModel {}
