import { Col, Layout, Row } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Spinner from '../../common/components/Spinner';
import { asset } from '../../common/utils/assets';
import api, { Workspace } from '../../api';
import Button from '../../common/components/Button';

export default function AuthorizeSlackPage() {
  const [searchParams] = useSearchParams();
  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const code = searchParams.get('code');
  const workspaceId = searchParams.get('state');
  const [error, setError] = useState(!code || !workspaceId);
  const [loading, setLoading] = useState(!error);

  useEffect(() => {
    if (code && workspaceId) {
      setLoading(true);

      api.integrations.slack.authorize({ code, workspaceId }).then(setWorkspace).catch(() => {
        setError(true);
      });

      setLoading(false);
    }
  }, [code, workspaceId]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <Layout.Content>
        <Row gutter={[16, 16]}>
          <Col
            span={24}
            md={12}
            style={{
              maxHeight: '100vh',
              overflow: 'auto',
            }}
          >
            <Row justify="center">
              <Col span={22} md={20} lg={18} xl={16}>
                <div
                  style={{
                    maxWidth: 400,
                    margin: 'auto',
                    minHeight: '100dvh',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="pb-4 pb-md-5 mt-5">
                    <img
                      src={asset('/static/img/zupport-logo-text-black.png')}
                      alt="Zupport Logo"
                      height={50}
                    />
                  </div>
                  <div className="mt-4 mt-md-5 pt-md-4 pb-4">
                    {loading && <Spinner />}
                    {
                      !loading && error && (
                        <p className="text-center">
                          Something went wrong while authorizing slack, please try again.
                        </p>
                      )
                    }
                    {
                      !loading && workspace && (
                        <div>
                          <h2>
                            Slack has been successfully connected to
                            {' '}
                            {workspace.name}
                            .
                          </h2>
                          <Link to={`/${workspace.slug}/settings/integrations`}>
                            <Button type="primary">
                              Setup Slack Integration
                            </Button>
                          </Link>
                        </div>
                      )
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                background: '#F9FAFB',
                height: '100vh',
                minHeight: '100vh',
                maxHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
              }}
              className="h-100"
            >
              <h1 className="mb-5">
                Slack Integration
              </h1>
              <div className="d-flex justify-content-end">
                <img
                  alt="Zupport Dashboard"
                  src="/img/dashboard.png"
                  style={{
                    width: '100%',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: 8,
                    marginRight: -200,
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </div>
  );
}
