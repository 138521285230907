import BaseService from './BaseService';
import Website from '../models/Website';
import Paginated from '../types/Paginated';
import WebsiteModule from '../models/WebsiteModule';

export default class Websites extends BaseService {
  async get(id: string): Promise<Website> {
    return this._get(`/websites/${id}`);
  }

  async patch(
    id: string,
    data: Partial<
    Pick<
    Website, 'name'
    | 'domain'
    | 'proactiveEngagement'
    | 'summary'
    | 'links'
    | 'faqs'
    | 'customerSupportAssistantId'
    | 'featureRequests'
    >
    >,
  ): Promise<Website> {
    return this._patch(`/websites/${id}`, data);
  }

  modules = {
    all: async (id: Website['_id']): Promise<Paginated<WebsiteModule>> => this._get(`/websites/${id}/modules/all`),
  };
}
