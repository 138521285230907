import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import AppPageContent from '../../layout/components/AppPageContent';
import useQuery from '../../common/hooks/useQuery';
import api, { FeatureRequest, FeatureRequestStatus } from '../../api';
import Spinner from '../../common/components/Spinner';
import { timeAgo } from '../../common/utils/dates';
import Select from '../../common/components/Select';
import { getFeatureRequestStatusLabel } from '../../client-widgets/utils/feature-requests';
import useRequest from '../../common/hooks/useRequest';
import { pluralize } from '../../common/utils/strings';

export default function FeatureRequestPage() {
  const { featureRequestId } = useParams<{ featureRequestId: string }>();

  const query = useQuery(
    async () => {
      if (!featureRequestId) {
        return null;
      }

      return api.featureRequests.get(featureRequestId);
    },
    [featureRequestId],
  );

  const update = useRequest(async (values: Partial<Pick<FeatureRequest, 'status'>>) => {
    if (!featureRequestId) {
      throw new Error('Feature request not found');
    }

    query.setData((prev) => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        ...values,
      };
    });

    return api.featureRequests.patch(featureRequestId, values);
  });

  const request = query?.data;

  return (
    <AppPageContent padding="normal" layout="centered">
      {
        query.loading && (
          <Spinner center />
        )
      }
      {
        request && (
          <Card
            title={request.title}
            extra={(
              <span className="d-flex align-items-center gap-3">
                {timeAgo(new Date(request.createdAt))}
                <Select
                  value={request.status}
                  onChange={(status) => {
                    update.submit({ status });
                  }}
                  size="small"
                  popupMatchSelectWidth={false}
                  options={Object
                    .values(FeatureRequestStatus)
                    .map((value) => ({
                      value,
                      label: getFeatureRequestStatusLabel(value),
                    }))}
                />
              </span>
            )}
          >
            <h3 className="mb-0">{`${pluralize(request.votes, 'Vote')}`}</h3>
            <h3 className="mb-0">Details</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: request.html,
              }}
            />
          </Card>
        )
      }
    </AppPageContent>
  );
}
