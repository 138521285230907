import React, { useEffect } from 'react';

import { Outlet, RouteObject } from 'react-router-dom';
import AppLayout from '../../layout/components/AppLayout';
import VerifyEmailPage from '../../auth/pages/VerifyEmailPage';
import WebsiteSettingsSetupPage from '../../website/pages/WebsiteSettingsSetupPage';
import WorkspacePageWrapper from '../../workspace/pages/WorkspacePageWrapper';
import api from '../../api';
import useActiveWorkspace from '../../workspace/hooks/useActiveWorkspace';
import { setWebsite } from '../../website/state';
import WebsiteSettingsPageLayout from '../../website/pages/WebsiteSettingsPageLayout';
import WebsiteProactiveEngagementSettings from '../../website/pages/WebsiteProactiveEngagementSettings';
import AssistantsPage from '../../assistants/pages/AssistantsPage';
import WebsiteDetailsSettingsPage from '../../website/pages/WebsiteDetailsSettingsPage';
import WebsiteLinksSettingsPage from '../../website/pages/WebsiteLinksSettingsPage';
import WebsiteFAQSettingsPage from '../../website/pages/WebsiteFAQsSectionPage';
import WorkspaceSettingsPagesWrapper from '../../workspace/pages/WorkspaceSettingsPagesWrapper';
import WorkspaceGeneralSettingsPage from '../../workspace/pages/WorkspaceGeneralSettingsPage';
import WorkspaceMembersSettingsPage from '../../workspace/pages/WorkspaceMembersSettingsPage';
import UserSettingsPageLayout from '../../user/pages/UserSettingsPageLayout';
import UserGeneralSettingsPage from '../../user/pages/UserGeneralSettingsPage';
import InboxPage from '../../inbox/pages/InboxPage';
import WorkspaceCreatePage from '../../workspace/pages/WorkspaceCreatePage';
import DashboardPage from '../../dashboard/pages/DashboardPage';
import WorkspaceBillingPage from '../../workspace/pages/WorkspaceBillingPage';
import HelpCentersPage from '../../help-center/pages/HelpCentersPage';
import CreateHelpCenterPage from '../../help-center/pages/CreateHelpCenterPage';
import HelpCenterPagesWrapper from '../../help-center/pages/HelpCenterPagesWrapper';
import HelpCenterGeneralPage from '../../help-center/pages/HelpCenterGeneralPage';
import HelpCenterDomainsPage from '../../help-center/pages/HelpCenterDomainsPage';
import HelpCenterConfigPage from '../../help-center/pages/HelpCenterConfigPage';
import WebsiteSettingsContentPage from '../../website/pages/WebsiteSettingsContentPage';
import HelpCenterLanguagesPage from '../../help-center/pages/HelpCenterLanguagesPage';
import HelpCenterArticlesPage from '../../help-center/pages/HelpCenterArticlesPage';
import HelpCenterCreateArticlePage from '../../help-center/pages/HelpCenterCreateArticlePage';
import HelpCenterArticlePage from '../../help-center/pages/HelpCenterArticlePage';
import WorkspaceIntegrationsPage from '../../workspace/pages/WorkspaceIntegrationsPage';
import AuthorizeSlackPage from '../../integrations/pages/AuthorizeSlackPage';
import WorkspaceUserSegmentsPage from '../../workspace/pages/WorkspaceUserSegmentsPage';
import FeatureRequestsPageLayout from '../../feature-requests/pages/FeatureRequestsPageLayout';
import FeatureRequestsPage from '../../feature-requests/pages/FeatureRequestsPage';
import FeatureRequestsSettingsPage from '../../feature-requests/pages/FeatureRequestsSettingsPage';
import FeatureRequestPage from '../../feature-requests/pages/FeatureRequestPage';
import WorkspaceDashboardPage from '../../workspace/pages/WorkspaceDashboardPage';
import MessengerWidgetConfigurationsPage from '../../messenger/pages/MessengerWidgetConfigurationsPage';
import MessengerWidgetConfigurationPage from '../../messenger/pages/MessengerWidgetConfigurationPageWrapper';
import MessengerWidgetConfigurationGeneralPage from '../../messenger/pages/MessengerWidgetConfigurationGeneralPage';
import MessengerWidgetConfigurationConfigPage from '../../messenger/pages/MessengerWidgetConfigurationConfigPage';

function NotFound() {
  return (
    <div>404 Not Found</div>
  );
}

function Wrapper() {
  const activeWorkspace = useActiveWorkspace();

  useEffect(() => {
    if (activeWorkspace) {
      api.websites.get(activeWorkspace.websiteId).then((website) => {
        setWebsite(website);
      });
    }
  }, [activeWorkspace?.slug]);

  return <Outlet />;
}

// eslint-disable-next-line import/prefer-default-export
export const appRoutes: RouteObject[] = [
  {
    element: <Wrapper />,
    children: [
      {
        element: <AppLayout />,
        children: [
          {
            path: '/',
            element: <DashboardPage />,
          },
          {
            path: '/workspaces/create',
            element: <WorkspaceCreatePage />,
          },
          {
            path: '/:workspaceSlug',
            element: <WorkspacePageWrapper />,
            children: [
              {
                path: '/:workspaceSlug/dashboard',
                element: <WorkspaceDashboardPage />,
              },
              {
                path: '/:workspaceSlug/inbox',
                element: <InboxPage />,
              },
              {
                path: '/:workspaceSlug/assistants',
                element: <AssistantsPage />,
              },
              {
                path: '/:workspaceSlug/help-centers',
                element: <HelpCentersPage />,
              },
              {
                path: '/:workspaceSlug/help-centers/create',
                element: <CreateHelpCenterPage />,
              },
              {
                path: '/:workspaceSlug/help-centers/:helpCenterSlug',
                element: <HelpCenterPagesWrapper />,
                children: [
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug',
                    element: <HelpCenterGeneralPage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/domains',
                    element: <HelpCenterDomainsPage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/styling',
                    element: <HelpCenterConfigPage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/languages',
                    element: <HelpCenterLanguagesPage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/articles',
                    element: <HelpCenterArticlesPage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/articles/create',
                    element: <HelpCenterCreateArticlePage />,
                  },
                  {
                    path: '/:workspaceSlug/help-centers/:helpCenterSlug/articles/:articleId',
                    element: <HelpCenterArticlePage />,
                  },
                ],
              },
              {
                path: '/:workspaceSlug/settings',
                element: <WorkspaceSettingsPagesWrapper />,
                children: [
                  {
                    path: '/:workspaceSlug/settings/general',
                    element: <WorkspaceGeneralSettingsPage />,
                  },
                  {
                    path: '/:workspaceSlug/settings/members',
                    element: <WorkspaceMembersSettingsPage />,
                  },
                  {
                    path: '/:workspaceSlug/settings/billing',
                    element: <WorkspaceBillingPage />,
                  },
                  {
                    path: '/:workspaceSlug/settings/integrations',
                    element: <WorkspaceIntegrationsPage />,
                  },
                  {
                    path: '/:workspaceSlug/settings/user-segments',
                    element: <WorkspaceUserSegmentsPage />,
                  },
                ],
              },
              {
                path: '/:workspaceSlug/website/settings',
                element: <WebsiteSettingsPageLayout />,
                children: [
                  {
                    path: '/:workspaceSlug/website/settings/setup',
                    element: <WebsiteSettingsSetupPage />,
                  },
                  {
                    path: '/:workspaceSlug/website/settings/proactive-engagement',
                    element: <WebsiteProactiveEngagementSettings />,
                  },
                  {
                    path: '/:workspaceSlug/website/settings/details',
                    element: <WebsiteDetailsSettingsPage />,
                  },
                  {
                    path: '/:workspaceSlug/website/settings/links',
                    element: <WebsiteLinksSettingsPage />,
                  },
                  {
                    path: '/:workspaceSlug/website/settings/faqs',
                    element: <WebsiteFAQSettingsPage />,
                  },
                  {
                    path: '/:workspaceSlug/website/settings/content',
                    element: <WebsiteSettingsContentPage />,
                  },
                ],
              },
              {
                path: '/:workspaceSlug/feature-requests/:featureRequestId',
                element: <FeatureRequestPage />,
              },
              {
                path: '/:workspaceSlug/feature-requests',
                element: <FeatureRequestsPageLayout />,
                children: [
                  {
                    path: '/:workspaceSlug/feature-requests/feature-requests',
                    element: <FeatureRequestsPage />,
                  },
                  {
                    path: '/:workspaceSlug/feature-requests/settings',
                    element: <FeatureRequestsSettingsPage />,
                  },
                ],
              },
              {
                path: '/:workspaceSlug/messenger/widget/configurations',
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <MessengerWidgetConfigurationsPage />,
                  },
                  {
                    path: ':messengerWidgetConfigurationId',
                    element: <MessengerWidgetConfigurationPage />,
                    children: [
                      {
                        path: '',
                        element: <MessengerWidgetConfigurationGeneralPage />,
                      },
                      {
                        path: 'configuration',
                        element: <MessengerWidgetConfigurationConfigPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '/settings',
            element: <UserSettingsPageLayout />,
            children: [
              {
                path: '/settings/general',
                element: <UserGeneralSettingsPage />,
              },
            ],
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: '/authorize/slack',
        element: <AuthorizeSlackPage />,
      },
      {
        path: '/verify-email',
        element: <VerifyEmailPage />,
      },
    ],
  },
];
