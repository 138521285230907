import {
  Card, Col, Form, message, Radio, Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { MinusOutlined } from '@ant-design/icons';
import Button from '../../common/components/Button';
import CardSwitch from '../../common/components/CardSwitch';
import useActiveWorkspace from '../hooks/useActiveWorkspace';
import WorkspaceSlackChannelsSelect from '../../integrations/components/WorkspaceSlackChannelsSelect';
import UserSegmentSelect from '../../user-segments/components/UserSegmentSelect';
import api, { SlackIntegrationOptions, SlackStreamMode } from '../../api';
import useRequest from '../../common/hooks/useRequest';
import { setActiveWorkspace } from '../state';

function buildSlackUrl(workspaceId: string) {
  const url = 'https://slack.com/oauth/v2/authorize';
  const scopes = ['chat:write', 'channels:read', 'groups:read', 'mpim:read', 'im:read'];
  const redirectUri = 'https://app.zupport.ai/authorize/slack';
  const clientId = '7728446525269.7724896398758';

  return `${url}?scope=${scopes.join(',')}&redirect_uri=${redirectUri}&client_id=${clientId}&state=${workspaceId}`;
}

export default function WorkspaceSlackIntegrationCard() {
  const workspace = useActiveWorkspace();

  const onEnableClick = () => {
    if (!workspace) {
      return void message.error('Workspace not found');
    }

    return void window.open(buildSlackUrl(workspace._id), '_blank');
  };

  const request = useRequest(async (values: SlackIntegrationOptions) => {
    if (!workspace) {
      throw new Error('Workspace not found');
    }

    return api.integrations.slack.patch(workspace._id, values);
  }, {
    onSuccess: (result) => {
      setActiveWorkspace(result);
      message.success('Settings saved');
    },
  });

  return (
    <CardSwitch
      title="Slack"
      subtitle="Enable Slack integration to send messages to Slack channels, including 2-way messaging."
      value={Boolean(workspace?.integrations?.slack?.enabled)}
      onChange={(value) => {
        if (!workspace) {
          return void message.error('Workspace not found');
        }

        if (value) {
          onEnableClick();
        }

        return void 0;
      }}
      enabledChildren={(
        <div>
          <Card size="small" className="my-4">
            <h4 className="m-0">
              Message Streams
            </h4>
            <p className="mt-0">
              Configure one or more message streams with Slack.
            </p>
            <Form
              layout="vertical"
              requiredMark={false}
              initialValues={{
                streams: workspace?.integrations?.slack?.streams || [],
              }}
              onFinish={request.submit}
            >
              <Form.List name="streams">
                {(fields, { add, remove }) => (
                  <div>
                    {
                      fields.map((field) => (
                        <Row
                          key={field.key}
                          gutter={[16, 16]}
                        >
                          <Col>
                            <Form.Item
                              name={[field.name, 'channelId']}
                              label="Channel"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a channel',
                                },
                              ]}
                            >
                              <WorkspaceSlackChannelsSelect
                                placeholder="Select a channel"
                                size="small"
                                showSearch
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name={[field.name, 'segmentId']}
                              label="Segment"
                            >
                              <UserSegmentSelect
                                placeholder="Select a segment"
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name={[field.name, 'mode']}
                              label="Mode"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a mode',
                                },
                              ]}
                            >
                              <Radio.Group
                                options={[
                                  {
                                    label: 'Initial Notification',
                                    value: SlackStreamMode.INITIAL,
                                  },
                                  {
                                    label: 'One-way',
                                    value: SlackStreamMode.ONE_WAY,
                                  },
                                  {
                                    label: 'Two-way (Coming Soon)',
                                    value: SlackStreamMode.TWO_WAY,
                                    disabled: true,
                                  },
                                ]}
                                optionType="button"
                                buttonStyle="outline"
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              label={(
                                <span>&nbsp;</span>
                              )}
                            >
                              <Button
                                shape="circle"
                                size="small"
                                icon={(
                                  <MinusOutlined />
                                )}
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))
                    }
                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={() => {
                          add({
                            channelId: null,
                            segmentId: null,
                            mode: 'initial',
                          });
                        }}
                        size="small"
                      >
                        Add Stream
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        htmlType="submit"
                        loading={request.loading}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </Form.List>
            </Form>
          </Card>
          <div className="d-flex justify-content-end">
            <Button
              type="default"
              className="mr-2"
              onClick={onEnableClick}
              size="small"
            >
              <Link to="/workspace/integrations/slack">Refresh</Link>
            </Button>
          </div>
        </div>
      )}
    />
  );
}
