import { useMatch, Outlet, useParams } from 'react-router-dom';
import { InfoOutlined, SettingOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import AppSubPageLayout from '../../layout/components/AppSubPageLayout';
import usePermissions from '../../permissions/hooks/usePermissions';
import { loadMessengerWidgetConfiguration, MessengerWidgetConfigurationStore } from '../state';
import Spinner from '../../common/components/Spinner';

export default function MessengerWidgetConfigurationPage() {
  const match = useMatch('/:workspaceSlug/messenger/widget/configurations/:id/:pageKey');
  const activePageKey = match?.params.pageKey || 'general';
  const permissions = usePermissions();
  const params = useParams<{ messengerWidgetConfigurationId: string }>();
  const loading = MessengerWidgetConfigurationStore.useState((s) => s.loading);
  const error = MessengerWidgetConfigurationStore.useState((s) => s.error);

  useEffect(() => {
    if (params.messengerWidgetConfigurationId) {
      loadMessengerWidgetConfiguration(params.messengerWidgetConfigurationId);
    }
  }, [params.messengerWidgetConfigurationId]);

  if (loading) {
    return (
      <Spinner center />
    );
  }

  if (error) {
    return (
      <p className="text-center">
        Error loading messenger widget configuration
      </p>
    );
  }

  return (
    <AppSubPageLayout
      allowed={permissions.featureRequests.manage}
      activePageKey={activePageKey}
      pages={[
        {
          key: 'general',
          title: 'General',
          path: '',
          icon: <InfoOutlined />,
        },
        {
          key: 'configuration',
          title: 'Configuration',
          path: 'configuration',
          icon: <SettingOutlined />,
        },
      ]}
      content={<Outlet />}
    />
  );
}
