import {
  MessengerWidgetConfigurationData,
} from '@zupport/types/models/MessengerWidgetConfiguration';
import { MessengerWidgetConfigurationStore } from '../state';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';

export default function useMessengerWidgetConfiguration() {
  const configuration = MessengerWidgetConfigurationStore.useState((s) => s.configuration);
  const loading = MessengerWidgetConfigurationStore.useState((s) => s.loading);

  const patch = useRequest(
    async (value: Partial<MessengerWidgetConfigurationData>) => {
      if (!configuration) {
        throw new Error('Configuration is not loaded');
      }

      return api.messengerWidgetConfigurations.patch(
        configuration._id,
        value,
      );
    },
    {
      onSuccess: (result) => {
        MessengerWidgetConfigurationStore.update((s) => ({
          ...s,
          configuration: result,
        }));
      },
    },
  );

  const patchMultimedia = useRequest(
    async (data: Partial<{ logoLight: File; logoDark: File }>) => {
      if (!configuration) {
        throw new Error('Configuration is not loaded');
      }

      return api.messengerWidgetConfigurations.patchMultimedia(
        configuration._id,
        data,
      );
    },
    {
      onSuccess: (result) => {
        MessengerWidgetConfigurationStore.update((s) => ({
          ...s,
          configuration: result,
        }));
      },
    },
  );

  return {
    configuration,
    loading,
    patch,
    patchMultimedia,
  };
}
