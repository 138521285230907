import { message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import CardSwitch from '../../common/components/CardSwitch';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';
import { setWebsite } from '../../website/state';

export default function FeatureRequestsSettingsPage() {
  const website = useActiveWebsite();

  const request = useRequest(
    async (value: boolean) => {
      if (!website) {
        throw new Error('Workspace not found');
      }

      setWebsite({
        ...website,
        featureRequests: {
          ...(website.featureRequests || {}),
          enabled: value,
        },
      });

      return api.websites.patch(website._id, {
        featureRequests: {
          ...(website.featureRequests || {}),
          enabled: value,
        },
      });
    },
    {
      onError: (error) => {
        message.error(error.message);
        if (website) {
          setWebsite({
            ...website,
            featureRequests: {
              ...(website.featureRequests || {}),
              enabled: !website.featureRequests?.enabled,
            },
          });
        }
      },
    },
  );

  return (
    <AppSubPageLayoutContent
      title="Feature Requests Settings"
      width="medium"
      content={(
        <CardSwitch
          title="Enable Feature Requests"
          subtitle="Allow users to submit feature requests through the widget."
          value={website?.featureRequests?.enabled}
          onChange={(value) => {
            request.submit(value).then(() => {
              void message.success(value ? 'Feature requests enabled' : 'Feature requests disabled');
            });
          }}
          enabledChildren={(
            <div>
              <CheckOutlined />
              {' '}
              Your users can now submit feature requests through the widget.
            </div>
          )}
        />
      )}
    />
  );
}
