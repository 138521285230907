import {
  Col, Form, Input, message, Radio, Row, Segmented, Upload,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import 'simplebar-react/dist/simplebar.min.css';
import MessengerWidgetConfiguration from '@zupport/types/models/MessengerWidgetConfiguration';
import SimpleBar from 'simplebar-react';
import { Color } from 'antd/es/color-picker';
import ImgCrop from 'antd-img-crop';
import { AppSubPageLayoutContent } from '../../../layout/components/AppSubPageLayout';
import useMessengerWidgetConfiguration from '../../hooks/useMessengerWidgetConfiguration';
import { getLanguageName } from '../../../common/config/languages';
import Button from '../../../common/components/Button';
import styles from './index.module.scss';
import { REACT_APP_WIDGETS_APP_URL } from '../../../config/env';
import useActiveWebsite from '../../../website/hooks/useActiveWebsite';
import ColorValueSplit from '../../../common/components/ColorValueSplit';
import FormColorPicker from '../../../common/components/FormColorPicker';
import theme from '../../../common/config/theme';
import { deepMerge } from '../../../common/utils/merge';
import CardCollapse from '../../../common/components/CardCollapse';
import { asset } from '../../../common/utils/assets';

function getLanguages(configuration: MessengerWidgetConfiguration | null) {
  if (!configuration) {
    return ['en'];
  }

  return configuration.languages.available.length > 0 ? configuration.languages.available : ['en'];
}

export default function MessengerWidgetConfigurationConfigPage() {
  const website = useActiveWebsite();
  const {
    configuration,
    patch,
    patchMultimedia,
  } = useMessengerWidgetConfiguration();
  const [form] = Form.useForm();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [logoLight, setLogoLight] = useState<File | null>(null);
  const [logoDark, setLogoDark] = useState<File | null>(null);
  const [logoLightURL, setLogoLightURL] = useState<string | undefined>();
  const [logoDarkURL, setLogoDarkURL] = useState<string | undefined>();

  const languages = getLanguages(configuration);

  const [language, setLanguage] = useState(getLanguageName(languages[0]));

  function setConfiguration() {
    if (iframeRef.current && configuration) {
      const data = form.getFieldsValue();
      iframeRef.current.contentWindow?.postMessage({
        message: 'zupport.chat.widget.preview.configuration:set',
        configuration: {
          ...configuration,
          ...data,
          logo: {
            light: logoLightURL,
            dark: logoDarkURL,
          },
        },
      }, REACT_APP_WIDGETS_APP_URL);
    }
  }

  useEffect(() => {
    if (iframeLoaded) {
      if (configuration) {
        setConfiguration();
      }

      if (iframeRef.current) {
        if (website) {
          iframeRef.current.contentWindow?.postMessage({
            message: 'zupport.chat.widget.preview.website:set',
            website,
          }, REACT_APP_WIDGETS_APP_URL);
        }
      }
    }
  }, [configuration, website, logoLightURL, logoDarkURL, iframeLoaded]);

  useEffect(() => {
    if (configuration) {
      if (configuration.logo) {
        if (configuration.logo.light) {
          setLogoLightURL(configuration.logo.light);
        }

        if (configuration.logo.dark) {
          setLogoDarkURL(configuration.logo.dark);
        }
      }
    }
  }, [configuration?._id]);

  if (!configuration) {
    return null;
  }

  const onFinish = async (values: Partial<MessengerWidgetConfiguration>) => {
    await patch.submit(values);

    if (logoLight || logoDark) {
      await patchMultimedia.submit({
        logoLight,
        logoDark,
      });
    }

    message.success('Configuration updated');
  };

  return (
    <Form
      form={form}
      className="flex-1"
      layout="vertical"
      initialValues={{ ...configuration }}
      onFinish={onFinish}
      onValuesChange={() => { setConfiguration(); }}
      preserve
    >
      <AppSubPageLayoutContent
        title="Configuration"
        width="full"
        titleAction={(
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
              loading={patch.loading || patchMultimedia.loading}
            >
              Save
            </Button>
          </Form.Item>
        )}
        content={(
          <div className={styles.contentContainer}>
            <Row className="h-100" gutter={[16, 16]}>
              <Col span={24} md={10} xl={14} xxl={16}>
                <SimpleBar className={styles.contentContainerLeft}>
                  <CardCollapse title="Texts and Messages" defaultOpen forceRender>
                    <Segmented
                      options={languages.map(getLanguageName)}
                      value={getLanguageName(language)}
                      onChange={setLanguage}
                      className="mb-4"
                    />
                    {
                      languages.map((item) => (
                        <div
                          key={item}
                          className={getLanguageName(item) === language ? '' : 'd-none'}
                        >
                          <Form.Item
                            name={['texts', 'greeting', item]}
                            label="Greeting"
                            extra="Displayed at the top of the widget, with a larger font size"
                          >
                            <Input placeholder="Greeting" />
                          </Form.Item>
                          <Form.Item
                            name={['texts', 'greetingSubtitle', item]}
                            label="Greeting Subtitle"
                            extra="Displayed below the greeting, with a smaller font size"
                          >
                            <Input placeholder="Greeting Subtitle" />
                          </Form.Item>
                        </div>
                      ))
                    }
                  </CardCollapse>
                  <CardCollapse title="Colors" className="mt-4" forceRender>
                    <ColorValueSplit
                      title="Accent Color"
                      extra="The color used for the main elements of the widget, like buttons, links, and other interactive elements"
                      light={(
                        <Form.Item name={['colors', 'accent', 'light']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                      dark={(
                        <Form.Item name={['colors', 'accent', 'dark']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                    />
                    <ColorValueSplit
                      title="Header Color"
                      extra="The color used for the header color"
                      light={(
                        <Form.Item name={['colors', 'headers', 'light']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                      dark={(
                        <Form.Item name={['colors', 'headers', 'dark']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                    />
                    <ColorValueSplit
                      title="Background Color"
                      extra="The background color of the widget"
                      light={(
                        <Form.Item name={['colors', 'background', 'light']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                      dark={(
                        <Form.Item name={['colors', 'background', 'dark']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                    />
                    <ColorValueSplit
                      title="Launcher Icon Background Color"
                      extra="The background color of the launcher button"
                      light={(
                        <Form.Item name={['colors', 'launcher', 'light']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                      dark={(
                        <Form.Item name={['colors', 'launcher', 'dark']} noStyle>
                          <FormColorPicker />
                        </Form.Item>
                      )}
                    />
                  </CardCollapse>
                  <CardCollapse title="Home Header Styling" className="mt-4" forceRender>
                    <Form.Item
                      name={['hero', 'background', 'type']}
                      className="pt-0"
                    >
                      <Radio.Group
                        options={[
                          {
                            label: 'Color',
                            value: 'color',
                          },
                          {
                            label: 'Gradient',
                            value: 'gradient',
                          },
                        ]}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const type = getFieldValue(['hero', 'background', 'type']);

                        if (type === 'color') {
                          return (
                            <ColorValueSplit
                              light={(
                                <Form.Item name={['hero', 'background', 'color', 'light']}>
                                  <FormColorPicker
                                    defaultValue={theme.colors.primary}
                                    showText
                                  />
                                </Form.Item>
                              )}
                              dark={(
                                <Form.Item name={['hero', 'background', 'color', 'dark']}>
                                  <FormColorPicker
                                    defaultValue={theme.colors.primary}
                                    showText
                                  />
                                </Form.Item>
                              )}
                            />
                          );
                        }

                        if (type === 'gradient') {
                          const gradient = getFieldValue(['hero', 'background', 'gradient']);
                          const light = ((gradient?.light || []) as (string | Color)[])
                            .map((item) => (typeof item === 'string' ? item : item.toCssString()));
                          const dark = ((gradient?.dark || []) as (string | Color)[])
                            .map((item) => (typeof item === 'string' ? item : item.toCssString()));

                          return (
                            <ColorValueSplit
                              light={(
                                <div>
                                  <div className="d-flex gap-2">
                                    <Form.Item
                                      name={['hero', 'background', 'gradient', 'light', 0]}
                                    >
                                      <FormColorPicker
                                        defaultValue={theme.colors.primary}
                                        showText
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name={['hero', 'background', 'gradient', 'light', 1]}
                                    >
                                      <FormColorPicker
                                        defaultValue="#FFF"
                                        showText
                                        onChange={(newValue) => {
                                          // This is a workaround to update the form value
                                          // because when selecting the second color,
                                          // the first color is not updated and it stays
                                          // undefined, which is causing the app to crash
                                          const firstColor = getFieldValue(['hero', 'background', 'gradient', 'light', 0]) || theme.colors.primary;
                                          const hero = form.getFieldValue('hero');
                                          form.setFieldsValue(deepMerge(hero, {
                                            hero: {
                                              background: {
                                                gradient: {
                                                  light: [firstColor, newValue],
                                                },
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div
                                    className="rounded-3"
                                    style={{
                                      background: `linear-gradient(${light[0] || theme.colors.primary}, ${light[1] || 'white'})`,
                                      height: 100,
                                    }}
                                  />
                                </div>
                              )}
                              dark={(
                                <div>
                                  <div className="d-flex gap-2">
                                    <Form.Item
                                      name={['hero', 'background', 'gradient', 'dark', 0]}
                                    >
                                      <FormColorPicker
                                        defaultValue={theme.colors.primary}
                                        showText
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name={['hero', 'background', 'gradient', 'dark', 1]}
                                    >
                                      <FormColorPicker
                                        defaultValue="#000"
                                        showText
                                        onChange={(newValue) => {
                                          // This is a workaround to update the form value
                                          // because when selecting the second color,
                                          // the first color is not updated and it stays
                                          // undefined, which is causing the app to crash
                                          const firstColor = getFieldValue(['hero', 'background', 'gradient', 'dark', 0]) || theme.colors.primary;
                                          const config = form.getFieldValue('config');
                                          form.setFieldsValue(deepMerge(config, {
                                            config: {
                                              hero: {
                                                background: {
                                                  gradient: {
                                                    dark: [firstColor, newValue],
                                                  },
                                                },
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div
                                    className="rounded-3"
                                    style={{
                                      background: `linear-gradient(${dark[0] || theme.colors.primary}, ${dark[1] || 'black'})`,
                                      height: 100,
                                    }}
                                  />
                                </div>
                              )}
                            />
                          );
                        }

                        return null;
                      }}
                    </Form.Item>
                  </CardCollapse>
                  <CardCollapse title="Logo" className="mt-4" forceRender>
                    <div className="flex" style={{ maxWidth: 200 }}>
                      <ColorValueSplit
                        light={(
                          <ImgCrop aspect={1} fillColor="transparent">
                            <Upload.Dragger
                              showUploadList={false}
                              beforeUpload={(file) => {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  setLogoLight(file);
                                  setLogoLightURL(
                                    e.target?.result as string,
                                  );
                                };
                                reader.readAsDataURL(file);
                                return false;
                              }}
                            >
                              <img
                                src={logoLight
                                  ? URL.createObjectURL(logoLight)
                                  : asset(logoLightURL)}
                                width="100%"
                                style={{
                                  aspectRatio: '1',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                }}
                                alt="Header Light"
                              />
                            </Upload.Dragger>
                          </ImgCrop>
                        )}
                        dark={(
                          <ImgCrop aspect={1} fillColor="transparent">
                            <Upload.Dragger
                              showUploadList={false}
                              beforeUpload={(file) => {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  setLogoDark(file);
                                  setLogoDarkURL(e.target?.result as string);
                                };
                                reader.readAsDataURL(file);
                                return false;
                              }}
                            >
                              <img
                                src={logoDark
                                  ? URL.createObjectURL(logoDark)
                                  : asset(logoDarkURL)}
                                width="100%"
                                style={{
                                  aspectRatio: '1',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                }}
                                alt="Header Dark"
                              />
                            </Upload.Dragger>
                          </ImgCrop>
                        )}
                        full
                      />
                    </div>
                  </CardCollapse>
                </SimpleBar>
              </Col>
              <Col span={24} md={14} xl={10} xxl={8}>
                <div className={styles.previewContainer}>
                  <iframe
                    ref={iframeRef}
                    title="Messenger Widget Preview"
                    src={`${REACT_APP_WIDGETS_APP_URL}/chat/widget/preview`}
                    className={styles.iframe}
                    onLoad={() => {
                      // Add a small timeout to allow the preview page to
                      // add the event listeners
                      setTimeout(() => {
                        setIframeLoaded(true);
                      }, 500);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
      />
    </Form>
  );
}
