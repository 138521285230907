import PricingPlan from '../types/PricingPlan';

const plans: PricingPlan[] = [
  {
    // Do not change the id, it has to be the same as in the backend
    id: 'starter',
    name: 'Starter',
    price: 29,
    aiRepliesCount: 2500,
    knowledgeBaseEnabled: false,
    proactiveEngagementEnabled: false,
  },
  {
    // Do not change the id, it has to be the same as in the backend
    id: 'professional',
    name: 'Professional',
    price: 59,
    aiRepliesCount: 6000,
    knowledgeBaseEnabled: true,
    proactiveEngagementEnabled: false,
  },
];

export default plans;
