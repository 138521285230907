import {
  Col, Divider, Form, Input, notification, Row,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api, { AssistantType, Website } from '../../api';
import useActiveWebsite from '../hooks/useActiveWebsite';
import { setWebsite } from '../state';
import { REACT_APP_API_URL } from '../../config/env';
import AssistantSelect from '../../assistants/components/AssistantSelect';

const DOMAIN_REGEX = /^(localhost|([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$)/;

function getInitializeCode(website?: Website): string {
  if (!website) {
    return '';
  }

  return `<script type="application/javascript">
  window.zupportSettings = {
    baseAPI: '${REACT_APP_API_URL}',
    websiteUid: '${website.uid}',
    userUid: 'your_user_uid', // leave empty if not logged in
    userEmail: 'your_user_email', // leave empty if not logged in
    userSecret: 'your_user_secret', // leave empty if not logged in
    userFirstName: 'your_user_first_name', // leave empty if not logged in
    userLastName: 'your_user_last_name', // leave empty if not logged in
    company: {
      id: 'company_id', // A unique ID for the company
      name: 'Company Name', // The name of the company
      mrr: 99, // Monthly Recurring Revenue
    },
    companyList: ['company_id_1', 'company_id_2'], // List of company IDs
  };
</script>`;
}

export default function WebsiteSettingsSetupPage() {
  const website = useActiveWebsite();
  const request = useRequest(async (data) => {
    if (!website) {
      throw new Error('Website not loaded yet');
    }

    return api.websites.patch(website._id, data);
  }, {
    onSuccess: (result) => {
      setWebsite(result);
      notification.success({
        message: 'Website updated',
      });
    },
  });

  const initializeCode = getInitializeCode(website);

  return (
    <Form
      layout="vertical"
      className="flex-1"
      initialValues={{
        name: website?.name || '',
        domain: website?.domain || '',
        customerSupportAssistantId: website?.customerSupportAssistantId || undefined,
      }}
      colon={false}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Setup"
        content={(
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <Form.Item name="name" label="Name" className="mb-0">
                <Input placeholder="Website Name" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="domain"
                label="Domain"
                rules={[
                  {
                    validator: async (_, value) => {
                      const domain = String((value || ''));
                      const domains = domain
                        .split(',')
                        .map((d) => d.trim().toLowerCase());

                      const badDomain = domains.find(
                        (item) => !DOMAIN_REGEX.test(item),
                      );

                      if (badDomain) {
                        throw new Error(`Invalid domain: ${badDomain}`);
                      }

                      if (domain.endsWith(',')) {
                        throw new Error('Invalid domain: trailing comma');
                      }

                      if (domains.length === 0) {
                        throw new Error('Please add at least a domain');
                      }
                    },
                  },
                ]}
                className="mb-0"
              >
                <Input placeholder="Website Domain" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="customerSupportAssistantId"
                label="Default Support Assistant"
                className="mb-0"
              >
                <AssistantSelect
                  placeholder="Select Assistant"
                  type={AssistantType.CUSTOMER_SUPPORT}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <h4>
                Step 1: Add the following code snippet to your website,
                on all the pages where you want the widget to appear.
              </h4>
              <div className="bg-light p-4 position-relative">
                <pre className="m-0 text-wrap">
                  {`<script src="${window.location.origin}/widget.js" defer></script>`}
                </pre>
                <Button
                  icon={<CopyOutlined />}
                  type="text"
                  onClick={() => {
                    navigator.clipboard.writeText(`<script src="${window.location.origin}/widget.js" defer></script>`);
                    notification.success({
                      message: 'Copied',
                    });
                  }}
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                  size="small"
                />
              </div>
              <Divider />
              <h4>
                Step 2: Initialize the widget with the following code snippet.
              </h4>
              <div className="bg-light p-4 position-relative">
                <pre className="m-0">
                  {initializeCode}
                </pre>
                <Button
                  icon={<CopyOutlined />}
                  type="text"
                  onClick={() => {
                    navigator.clipboard.writeText(initializeCode);
                    notification.success({
                      message: 'Copied',
                    });
                  }}
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                  size="small"
                />
              </div>
              <p className="text-muted">
                The widget will work only on the specified domain, and all its subdomains.
              </p>
            </Col>
          </Row>
        )}
        footer={(
          <div className="d-flex justify-content-end flex-1">
            <Button
              type="primary"
              htmlType="submit"
              loading={request.loading}
            >
              Save
            </Button>
          </div>
        )}
      />
    </Form>
  );
}
