import { ReactNode } from 'react';
import LightModeIcon from '../Icons/LightIcon';
import DarkModeIcon from '../Icons/DarkModeIcon';

interface ColorValueContainerProps {
  name: string;
  icon: ReactNode;
  children: ReactNode;
}

function ColorValueContainer(props: ColorValueContainerProps) {
  const { name, icon, children } = props;

  return (
    <div>
      <div className="d-flex align-items-center gap-2">
        {icon}
        <span>{name}</span>
      </div>
      <div className="mt-2">
        {children}
      </div>
    </div>
  );
}

interface ColorValueSplitProps {
  light: ReactNode;
  dark: ReactNode;
  title?: string;
  extra?: ReactNode;
  full?: boolean;
}

export default function ColorValueSplit(props: ColorValueSplitProps) {
  const {
    title, light, dark, full, extra,
  } = props;

  return (
    <div>
      {
        title && (
          <p className="fw-bold">{title}</p>
        )
      }
      <div className="d-flex gap-2">
        <div
          style={{
            maxWidth: '50%',
            flex: full ? 1 : 'none',
          }}
        >
          <ColorValueContainer
            name="Light"
            icon={<LightModeIcon />}
          >
            {light}
          </ColorValueContainer>
        </div>
        <div
          style={{
            borderRight: '1px solid #f0f0f0',
          }}
        />
        <div
          style={{
            flex: full ? 1 : 'none',
          }}
        >
          <ColorValueContainer
            name="Dark"
            icon={<DarkModeIcon />}
          >
            {dark}
          </ColorValueContainer>
        </div>
      </div>
      {
        extra && (
          <div className="mt-2 text-secondary">
            {extra}
          </div>
        )
      }
    </div>
  );
}
