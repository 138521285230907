import { REACT_APP_CDN_URL } from '../../config/env';

const RAW_FALLBACK = '/static/img/placeholder-image.webp';

export function asset(path?: string, fallback = RAW_FALLBACK): string {
  if (!path) {
    return asset(fallback);
  }

  return `${REACT_APP_CDN_URL}${path}`;
}

export const FALLBACK_IMAGE_URL = asset('/static/img/placeholder-image.webp');
