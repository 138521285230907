import {
  AddFileButton,
  createReactBlockSpec,
  DefaultFilePreview,
  FileAndCaptionWrapper,
  ImageToExternalHTML, ReactCustomBlockRenderProps, ResizeHandlesWrapper, useResolveUrl,
} from '@blocknote/react';
import {
  FileBlockConfig, imageBlockConfig, imageParse, imagePropSchema,
} from '@blocknote/core';
import React, { useState } from 'react';

function FileImageOutlined() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <polygon
        points="152 224 104 152 76.36 193.46 60 168 24 224 152 224"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <polyline
        points="152 32 152 88 208 88"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M192,224h8a8,8,0,0,0,8-8V88L152,32H56a8,8,0,0,0-8,8v88"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

const customImage = {
  ...imageBlockConfig,
  propSchema: {
    ...imagePropSchema,
    previewWidthRelative: {
      default: '',
    },
  },
};

export function ImagePreview(props: Omit<
ReactCustomBlockRenderProps<FileBlockConfig, any, any>,
'contentRef'
>) {
  const {
    block,
    editor,
  } = props;
  const [width, setWidth] = useState<number>(
    Math.min(
      block.props.previewWidth!,
      editor.domElement.firstElementChild!.clientWidth,
    ),
  );

  return (
    <ResizeHandlesWrapper {...props} width={width} setWidth={setWidth}>
      <img
        className="bn-visual-media"
        src={block.props.url}
        alt={block.props.caption || block.props.name || 'BlockNote image'}
        contentEditable={false}
        draggable={false}
        width={width}
      />
    </ResizeHandlesWrapper>
  );
}

type ImagePreviewCustomProps = Omit<ReactCustomBlockRenderProps<FileBlockConfig, any, any>, 'contentRef'>;

function ImagePreviewCustom(props: ImagePreviewCustomProps) {
  const {
    block,
  } = props;
  const resolved = useResolveUrl(block.props.url!);

  return (
    <img
      className="bn-visual-media"
      src={resolved.downloadUrl}
      alt={block.props.caption || block.props.name || 'BlockNote image'}
      contentEditable={false}
      draggable={false}
      width={block.props.previewWidthRelative}
    />
  );
}

const ReactImageBlock = createReactBlockSpec(customImage, {
  render: (props) => {
    const { block, editor } = props;

    let content = null;

    if (block.props.url === '') {
      content = (
        <AddFileButton
          {...props}
          editor={editor as any}
          buttonText={editor.dictionary.file_blocks.image.add_button_text}
          buttonIcon={<FileImageOutlined />}
        />
      );
    } else if (!block.props.showPreview) {
      content = (
        <FileAndCaptionWrapper block={block} editor={editor as any}>
          <DefaultFilePreview
            block={block}
            editor={editor as any}
          />
        </FileAndCaptionWrapper>
      );
    } else {
      content = (
        <FileAndCaptionWrapper block={block} editor={editor as any}>
          {
            block.props.previewWidthRelative ? (
              <ImagePreviewCustom block={block} editor={editor as any} />
            ) : (
              <ImagePreview
                block={block}
                editor={editor as any}
              />
            )
          }
        </FileAndCaptionWrapper>
      );
    }

    return (
      <div className="bn-file-block-content-wrapper">
        {content}
      </div>
    );
  },
  parse: imageParse,
  // @ts-ignore
  toExternalHTML: (props) => <ImageToExternalHTML {...props} />,
});

export default ReactImageBlock;
