export default function PlugConnectedIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24px" height="24px">
        <rect width="256" height="256" fill="none" />
        <rect
          x="63.03"
          y="88.4"
          width="129.94"
          height="79.2"
          rx="24"
          transform="translate(-53.02 128) rotate(-45)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="88"
          y1="88"
          x2="168"
          y2="168"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="232"
          y1="24"
          x2="173.94"
          y2="82.06"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="82.06"
          y1="173.94"
          x2="24"
          y2="232"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="96"
          y1="32"
          x2="104"
          y2="52"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="32"
          y1="96"
          x2="52"
          y2="104"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="204"
          y1="152"
          x2="224"
          y2="160"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
        <line
          x1="152"
          y1="204"
          x2="160"
          y2="224"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </svg>
    </span>
  );
}
