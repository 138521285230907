import {
  Col, Form, Input, Row,
} from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import Button from '../../../common/components/Button';
import Select from '../../../common/components/Select';
import api, { UserSegment, UserSegmentData, UserSegmentGroupOperator } from '../../../api';
import { userSegmentRuleAttributeConditions, userSegmentRuleAttributes } from '../../logic/config';
import useRequest from '../../../common/hooks/useRequest';
import useActiveWorkspaceId from '../../../workspace/hooks/useActiveWorkspaceId';
import useActiveWebsite from '../../../website/hooks/useActiveWebsite';

interface UserSegmentConstructorProps {
  onCancel?: () => void;
  segment?: UserSegment;
  onSuccess?: (segment: UserSegment) => void;
}

export default function UserSegmentConstructor(props: UserSegmentConstructorProps) {
  const { onCancel, segment, onSuccess } = props;
  const workspaceId = useActiveWorkspaceId();
  const website = useActiveWebsite();

  const request = useRequest(
    async (data: Omit<UserSegmentData, 'websiteId' | 'workspaceId'>) => {
      if (!website) {
        throw new Error('Website is not active');
      }

      if (!workspaceId) {
        throw new Error('Workspace is not active');
      }

      if (segment) {
        return api.userSegments.patch(segment._id, data);
      }

      return api.userSegments.create({
        ...data,
        websiteId: website._id,
        workspaceId,
      });
    },
    {
      onSuccess,
    },
  );

  return (
    <Form<UserSegmentData>
      layout="vertical"
      requiredMark={false}
      initialValues={{
        name: '',
        definition: {
          operator: UserSegmentGroupOperator.AND,
          rules: [
            {},
          ],
        },
        ...(segment || {}),
      }}
      onFinish={request.submit}
    >
      <Form.Item
        label="Segment Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input the segment name!',
          },
        ]}
      >
        <Input placeholder="Segment Name" />
      </Form.Item>
      <p>Rules</p>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const rules = getFieldValue(['definition', 'rules']);

          return (
            <Form.Item
              name={['definition', 'operator']}
              className={rules.length < 2 ? 'd-none' : undefined}
            >
              <Select
                options={[
                  {
                    label: 'OR',
                    value: 'or',
                  },
                  {
                    label: 'AND',
                    value: 'and',
                  },
                ]}
                size="small"
                className="w-auto"
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.List
        name={['definition', 'rules']}
      >
        {(fields, { add, remove }) => (
          <div>
            {
              fields.map((field) => (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const attributeName = getFieldValue([
                      'definition',
                      'rules',
                      field.name,
                      'attribute',
                    ]);

                    const attribute = userSegmentRuleAttributes[attributeName];

                    return (
                      <Row
                        className="mb-2"
                        gutter={[16, 16]}
                        key={field.key}
                      >
                        <Col>
                          <Form.Item
                            name={[field.name, 'attribute']}
                            noStyle
                          >
                            <Select
                              placeholder="Select an attribute"
                              options={Object.keys(userSegmentRuleAttributes).map((itemName) => {
                                const item = userSegmentRuleAttributes[itemName];

                                return {
                                  label: item.label,
                                  value: item.attribute,
                                };
                              })}
                              size="small"
                              variant="filled"
                              popupMatchSelectWidth={false}
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                        {
                          attribute && (
                            <>
                              <Col>
                                <Form.Item
                                  name={[field.name, 'condition']}
                                  noStyle
                                >
                                  <Select
                                    placeholder="Select a condition"
                                    options={attribute.conditions.map((conditionName) => {
                                      const condition = userSegmentRuleAttributeConditions[
                                        conditionName
                                      ];

                                      return {
                                        label: condition.label,
                                        value: conditionName,
                                      };
                                    })}
                                    size="small"
                                    variant="filled"
                                  />
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  name={[field.name, 'value']}
                                  noStyle
                                >
                                  <Input
                                    placeholder="Value"
                                    size="small"
                                    suffix={attribute.unitSuffix}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )
                        }
                        <Col>
                          <Button
                            icon={<MinusOutlined />}
                            type="default"
                            size="small"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  }}
                </Form.Item>
              ))
            }
            <Button
              type="default"
              size="small"
              onClick={() => add({
                attribute: null,
                condition: '',
                value: '',
              })}
            >
              Add Rule
            </Button>
          </div>
        )}
      </Form.List>
      <div className="d-flex justify-content-between mt-5">
        <Button
          type="default"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={request.loading}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}
