import Select, { SelectProps } from '../../common/components/Select';
import useWorkspaceSlackChannels from '../hooks/useWorkspaceSlackChannels';

interface WorkspaceSlackChannelsSelectProps extends Omit<SelectProps, 'options'> {}

export default function WorkspaceSlackChannelsSelect(props: WorkspaceSlackChannelsSelectProps) {
  const channels = useWorkspaceSlackChannels();

  return (
    <Select
      options={channels.map((channel) => ({
        label: channel.name,
        value: channel.id,
      }))}
      {...props}
    />
  );
}
