import { getDefaultReactSlashMenuItems, SuggestionMenuController } from '@blocknote/react';
import { BlockNoteEditor, insertOrUpdateBlock } from '@blocknote/core';
import React from 'react';

interface EditorSuggestionMenuControllerProps {
  editor: BlockNoteEditor<any, any, any>;
}

function FileImageOutlined() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <polygon
        points="152 224 104 152 76.36 193.46 60 168 24 224 152 224"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <polyline
        points="152 32 152 88 208 88"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M192,224h8a8,8,0,0,0,8-8V88L152,32H56a8,8,0,0,0-8,8v88"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

export default function EditorSuggestionMenuController(props: EditorSuggestionMenuControllerProps) {
  const { editor } = props;

  return (
    <SuggestionMenuController
      triggerCharacter="/"
      getItems={async (query) => {
        const available = [
          ...getDefaultReactSlashMenuItems(editor)
            .filter((item) => item.title !== 'Emoji'),
          {
            icon: <FileImageOutlined />,
            onItemClick: () => {
              const insertedBlock = insertOrUpdateBlock(editor, {
                type: 'image',
              });

              // Immediately open the file toolbar
              editor.dispatch(
                // eslint-disable-next-line no-underscore-dangle
                editor._tiptapEditor.state.tr.setMeta(editor.filePanel!.plugin, {
                  block: insertedBlock,
                }),
              );
            },
            key: 'image',
            ...editor.dictionary.slash_menu.image,
          },
        ];

        const filtered = available.filter((item) => {
          const cleanSearch = query.toLowerCase().trim();

          return item.title.toLowerCase().includes(cleanSearch);
        });

        return filtered.length > 0 ? filtered : available;
      }}
    />
  );
}
