import { Card, Collapse } from 'antd';
import { ReactNode } from 'react';
import styles from './styles.module.scss';
import cn from '../../utils/cn';

const key = 'item';

interface CardCollapseProps {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
  className?: string;
  forceRender?: boolean;
}

export default function CardCollapse(props: CardCollapseProps) {
  const {
    title, children, defaultOpen, className, forceRender,
  } = props;

  return (
    <Card bordered className={cn(styles.container, className)}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={defaultOpen ? [key] : undefined}
        items={[{
          key,
          label: (
            <div>
              <h3 className="m-0">{title}</h3>
            </div>
          ),
          children,
          forceRender,
        }]}
      />
    </Card>
  );
}
