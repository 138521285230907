import { FeatureRequestStatus } from '../../api';

type FeatureRequestsLabels = Record<FeatureRequestStatus, string>;

const featureRequestsLabels: FeatureRequestsLabels = {
  [FeatureRequestStatus.OPEN]: 'Open',
  [FeatureRequestStatus.ACCEPTED]: 'Accepted',
  [FeatureRequestStatus.IN_PROGRESS]: 'In Progress',
  [FeatureRequestStatus.IMPLEMENTED]: 'Implemented',
  [FeatureRequestStatus.DECLINED]: 'Declined',
};

// eslint-disable-next-line import/prefer-default-export
export function getFeatureRequestStatusLabel(status: FeatureRequestStatus): string {
  return featureRequestsLabels[status] || 'Unknown';
}
