import { useEffect } from 'react';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';
import { UserSegmentsStore } from '../state';
import api from '../../api';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';

export default function useUserSegments() {
  const workspaceId = useActiveWorkspaceId();
  const website = useActiveWebsite();
  const initialized = UserSegmentsStore.useState((s) => s.initialized);
  const segments = UserSegmentsStore.useState((s) => s.segments);

  useEffect(() => {
    if (!workspaceId || initialized || !website) {
      return;
    }

    UserSegmentsStore.update((s) => ({
      ...s,
      initialized: true,
    }));

    api.userSegments.list({ workspaceId, websiteId: website?._id })
      .then((data) => {
        UserSegmentsStore.update((s) => ({
          ...s,
          initialized: true,
          segments: data.items,
        }));
      });
  }, [initialized, workspaceId]);

  return segments;
}
