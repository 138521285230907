import BaseService from './BaseService';

type SummaryReportsParams = {
  from?: string;
  to?: string;
};

export type SummaryReports = {
  totalConversationsCount: number;
  totalProactiveConversationsCount: number;
  resolvedConversationsCount: number;
  resolvedConversationsRate: number;
  conversationsByResolution: number;
};

export default class Reports extends BaseService {
  async summary(workspaceId: string, params: SummaryReportsParams): Promise<SummaryReports> {
    return this._get(`/reports/${workspaceId}/summary`, { params });
  }
}
