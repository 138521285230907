import { Form, Input } from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import useMessengerWidgetConfiguration from '../hooks/useMessengerWidgetConfiguration';
import Button from '../../common/components/Button';
import UserSegmentSelect from '../../user-segments/components/UserSegmentSelect';
import CardSwitch from '../../common/components/CardSwitch';
import Select from '../../common/components/Select';
import languages from '../../common/config/languages';

export default function MessengerWidgetConfigurationGeneralPage() {
  const {
    configuration,
    patch,
  } = useMessengerWidgetConfiguration();

  if (!configuration) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      className="flex-1"
      onFinish={patch.submit}
      initialValues={{
        ...configuration,
      }}
    >
      <AppSubPageLayoutContent
        title="General"
        width="medium"
        content={(
          <div>
            <Form.Item name="enabled">
              <CardSwitch
                title="Enable configuration"
                subtitle="Enable this configuration to use it in the widget, otherwise it will be ignored"
              />
            </Form.Item>
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item
              name="userSegments"
              label="User segments"
              extra={(
                <div>
                  Select which segments of users should see this widget.
                  We will automatically show the widget to all users if no segments are selected.
                </div>
              )}
            >
              <UserSegmentSelect
                placeholder="Select user segments"
                mode="multiple"
              />
            </Form.Item>
            <Form.Item name={['languages', 'enable']}>
              <CardSwitch
                title="Enable multi-language"
                subtitle="Customize your widget for different languages"
                enabledChildren={(
                  <>
                    <Form.Item name={['languages', 'default']} label="Default Language">
                      <Select options={languages} placeholder="Select default language" />
                    </Form.Item>
                    <Form.Item name={['languages', 'available']} label="Available Languages">
                      <Select
                        options={languages}
                        mode="multiple"
                      />
                    </Form.Item>
                  </>
                )}
              />
            </Form.Item>
          </div>
        )}
        footer={(
          <div className="d-flex justify-content-end flex-1">
            <Form.Item noStyle>
              <Button
                loading={patch.loading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        )}
      />
    </Form>
  );
}
