import { useState } from 'react';
import { Card } from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';
import UserSegmentConstructor from '../../user-segments/components/UserSegmentConstructor';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';
import useActiveWorkspaceId from '../hooks/useActiveWorkspaceId';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import UserSegmentCard from '../../user-segments/components/UserSegmentCard';

export default function WorkspaceUserSegmentsPage() {
  const website = useActiveWebsite();
  const workspaceId = useActiveWorkspaceId();
  const [createOpen, setCreateOpen] = useState(false);

  const query = useQuery(
    async () => {
      if (!website || !workspaceId) {
        return null;
      }

      return api.userSegments.list({
        websiteId: website._id,
        workspaceId,
      });
    },
  );

  return (
    <AppSubPageLayoutContent
      title="User Segments"
      titleAction={(
        <Button
          type="default"
          onClick={() => {
            setCreateOpen(true);
          }}
        >
          Create Segment
        </Button>
      )}
      width="medium"
      content={(
        <div>
          {
            createOpen && (
              <Card title="New User Segment" className="mb-2">
                <UserSegmentConstructor
                  onCancel={() => { setCreateOpen(false); }}
                  onSuccess={(result) => {
                    query.setData((prev) => {
                      if (!prev) {
                        return prev;
                      }

                      return {
                        ...prev,
                        items: [
                          result,
                          ...prev.items,
                        ],
                      };
                    });
                    setCreateOpen(false);
                  }}
                />
              </Card>
            )
          }
          {
            query.data && query.data.items.map((segment) => (
              <UserSegmentCard
                key={segment._id}
                segment={segment}
                onChange={(result) => {
                  query.setData((prev) => {
                    if (!prev) {
                      return prev;
                    }

                    return {
                      ...prev,
                      items: prev.items.map((item) => {
                        if (item._id === result._id) {
                          return result;
                        }

                        return item;
                      }),
                    };
                  });
                }}
              />
            ))
          }
        </div>
      )}
    />
  );
}
