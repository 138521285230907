import colors from './colors.js';
var defaultMessengerWidgetConfig = {
    languages: {
        enable: false,
        default: 'en',
        available: ['en'],
    },
    texts: {
        greeting: {
            en: 'Hello!',
        },
        greetingSubtitle: {
            en: 'How can we help you today?',
        },
    },
    colors: {
        accent: {
            light: colors.primary,
            dark: colors.primary,
        },
        headers: {
            light: colors.primary,
            dark: colors.primary,
        },
        background: {
            light: colors.light,
            dark: colors.dark,
        },
        launcher: {
            light: colors.primary,
            dark: colors.primary,
        },
    },
    hero: {
        background: {
            type: 'gradient',
            color: {
                light: colors.primary,
                dark: colors.primary,
            },
            gradient: {
                light: [colors.primary, colors.light],
                dark: [colors.primary, colors.dark],
            },
        },
    },
    logo: {
        light: undefined,
        dark: undefined,
    },
};
export default defaultMessengerWidgetConfig;
