import BaseService from './BaseService';
import Paginated from '../types/Paginated';
import Query from '../types/Query';
import FeatureRequest, { FeatureRequestSearchDocument } from '../models/FeatureRequest';
import SearchResult from '../models/SearchResult';

export default class FeatureRequests extends BaseService {
  async get(id: string): Promise<FeatureRequest> {
    return this._get(`/feature-requests/${id}`);
  }

  async search(
    params: Query<{ workspaceId: string; sort?: string; search?: string; }>,
  ): Promise<Paginated<SearchResult<FeatureRequestSearchDocument>>> {
    return this._get('/feature-requests/search', { params });
  }

  async list(
    params: Query<{ workspaceId: string; from?: string; to?: string; }>,
  ): Promise<Paginated<FeatureRequest>> {
    return this._get('/feature-requests', { params });
  }

  async patch(id: string, data: Partial<Pick<FeatureRequest, 'status' | 'title'>>): Promise<void> {
    return this._patch(`/feature-requests/${id}`, data);
  }
}
