import { useEffect } from 'react';
import { IntegrationsStore } from '../state';
import api from '../../api';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';

export default function useWorkspaceSlackChannels() {
  const workspaceId = useActiveWorkspaceId();
  const initialized = IntegrationsStore.useState((s) => s.slack.initialized);
  const channels = IntegrationsStore.useState((s) => s.slack.channels);

  useEffect(() => {
    if (!initialized && workspaceId) {
      IntegrationsStore.update((s) => ({
        ...s,
        slack: {
          ...s.slack,
          initialized: true,
          loading: true,
        },
      }));

      api.integrations.slack.channels.list(workspaceId).then((result) => {
        IntegrationsStore.update((s) => ({
          ...s,
          slack: {
            ...s.slack,
            loading: false,
            channels: result,
          },
        }));
      });
    }
  }, [initialized, workspaceId]);

  return channels;
}
