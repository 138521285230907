import {
  createBrowserRouter, Outlet, RouterProvider, useMatch, useNavigate,
} from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { guestRoutes } from './GuestNavigator';
import { appRoutes } from './AppNavigator';
import useScript from '../../common/hooks/useScript';
import useUser from '../../auth/hooks/useUser';
import { AuthStore } from '../../auth/state';
import useAuth from '../../auth/hooks/useAuth';
import { IS_PRODUCTION, REACT_APP_API_URL, REACT_APP_ZUPPORT_WEBSITE_UID } from '../../config/env';
import InvitePage from '../../auth/pages/InvitePage';
import useActiveWorkspace from '../../workspace/hooks/useActiveWorkspace';

function Content() {
  useScript(!IS_PRODUCTION ? 'http://localhost:3100/widget.js' : 'https://app.zupport.ai/widget.js');

  const user = useUser();
  const navigate = useNavigate();
  const token = AuthStore.useState((s) => s.token);
  const authenticated = AuthStore.useState((s) => s.authenticated);
  const isVerifyEmailPage = useMatch('/verify-email');
  const workspace = useActiveWorkspace();
  const auth = useAuth();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    auth.authenticate().then(() => {
      setInitialized(true);
    }).catch(() => {
      notification.error({
        message: 'Authentication failed',
        description: 'Please login again',
      });
    });
  }, [token]);

  useEffect(() => () => {
    setInitialized(false);
  }, [authenticated]);

  useEffect(() => {
    // @ts-ignore
    window.zupportSettings = {
      baseAPI: REACT_APP_API_URL,
      websiteUid: REACT_APP_ZUPPORT_WEBSITE_UID,
      userUid: user?._id,
      userEmail: user?.email,
      userSecret: user?._id,
      userFirstName: user?.firstName,
      userLastName: user?.lastName,
      company: workspace ? {
        id: workspace._id,
        name: workspace.name,
        mrr: workspace.mrr,
      } : undefined,
      companyList: user?.workspaceIds,
    };
  }, [user, workspace]);

  if (!initialized) {
    return null;
  }

  if (user && !user.verified && !isVerifyEmailPage) {
    navigate('/verify-email');
    return null;
  }

  return (
    <Outlet />
  );
}

export default function Navigator() {
  const { authenticated } = useAuth();

  const router = useMemo(() => createBrowserRouter([
    {
      path: '/invites/:workspaceUserId/:token',
      element: <InvitePage />,
    },
    {
      element: <Content />,
      children: authenticated ? appRoutes : guestRoutes,
    },
  ]), [authenticated]);

  return (<RouterProvider router={router} />);
}
