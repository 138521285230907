import { Store } from 'pullstate';
import MessengerWidgetConfiguration from '@zupport/types/models/MessengerWidgetConfiguration';
import api from '../../api';

type State = {
  configuration: MessengerWidgetConfiguration | null;
  loading: boolean;
  error: boolean;
};

export const MessengerWidgetConfigurationStore = new Store<State>({
  configuration: null,
  loading: true,
  error: false,
});

export function loadMessengerWidgetConfiguration(id: string) {
  MessengerWidgetConfigurationStore.update((s) => ({
    ...s,
    loading: true,
    error: false,
  }));

  api.messengerWidgetConfigurations.get(id).then((configuration) => {
    MessengerWidgetConfigurationStore.update((s) => ({
      ...s,
      configuration,
      loading: false,
    }));
  }).catch(() => {
    MessengerWidgetConfigurationStore.update((s) => ({
      ...s,
      loading: false,
      error: true,
    }));
  });
}
