import { ReactNode, RefObject } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import useIsMobile from '../../common/hooks/useIsMobile';
import cn from '../../common/utils/cn';
import PermissionChecker from '../../common/components/PermissionChecker';

type MultiPageLayoutPage = {
  key: string;
  title: string;
  path: string;
  icon?: ReactNode;
};

export interface AppSubPageLayoutProps {
  pages: MultiPageLayoutPage[];
  activePageKey: MultiPageLayoutPage['key'];
  content: ReactNode;
  fullWidth?: boolean;
  allowed?: boolean;
}

interface AppSubPageLayoutContentProps {
  title: string;
  content: ReactNode;
  sidebar?: ReactNode;
  footer?: ReactNode;
  contentContainerRef?: RefObject<HTMLDivElement>;
  titleAction?: ReactNode;
  width?: 'full' | 'medium';
}

export function AppSubPageLayoutContent(props: AppSubPageLayoutContentProps) {
  const {
    title,
    content,
    sidebar,
    footer,
    contentContainerRef,
    titleAction,
    width = 'full',
  } = props;

  return (
    <div className={cn(
      'app-sub-page-layout-main-content-container',
      `app-sub-page-layout-main-content-container-width-${width}`,
    )}
    >
      <div className="app-sub-page-layout-content-right">
        <div className="app-sub-page-layout-content-container">
          <div className="app-sub-page-layout-header">
            <h3 className="app-sub-page-layout-header-title">
              {title}
            </h3>
            {titleAction}
          </div>
          <SimpleBar
            className={cn(
              'app-sub-page-layout-content',
              Boolean(footer) && 'app-sub-page-layout-content-with-footer',
            )}
            scrollableNodeProps={{ ref: contentContainerRef }}
          >
            {content}
          </SimpleBar>
        </div>
        {
          footer && (
            <div className="app-sub-page-layout-footer">
              {footer}
            </div>
          )
        }
      </div>
      {
        sidebar && (
          <div className="app-sub-page-layout-content-sidebar">
            <SimpleBar>
              {sidebar}
            </SimpleBar>
          </div>
        )
      }
    </div>
  );
}

export default function AppSubPageLayout(props: AppSubPageLayoutProps) {
  const {
    pages,
    activePageKey,
    content,
    fullWidth,
    allowed = true,
  } = props;

  const isMobile = useIsMobile(999);

  return (
    <PermissionChecker allowed={allowed}>
      <div className="app-sub-page-layout-container">
        <div
          className={cn(
            'app-sub-page-layout',
            fullWidth && 'app-sub-page-layout-full-width',
          )}
        >
          <div className="app-sub-page-layout-sidebar">
            <Menu
              defaultSelectedKeys={[activePageKey]}
              selectedKeys={[activePageKey]}
              items={pages.map((page) => ({
                key: page.key,
                icon: page.icon,
                label: (
                  <Link to={page.path}>
                    {page.title}
                  </Link>
                ),
                to: page.path,
              }))}
              mode={isMobile ? 'horizontal' : 'vertical'}
            />
          </div>
          {content}
        </div>
      </div>
    </PermissionChecker>
  );
}
