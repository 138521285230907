import { List, message, Tooltip } from 'antd';
import { LinkOutlined, ReloadOutlined } from '@ant-design/icons';
import api, { ContentWebsitePageCrawlingStatus } from '../../../api';
import { getDayTimeDisplay } from '../../../common/utils/dates';
import WebsiteStatusTag from '../WebsiteStatusTag';
import Button from '../../../common/components/Button';
import FlowArrowIcon from '../../../common/components/Icons/FlowArrowIcon';
import useRequest from '../../../common/hooks/useRequest';

interface WebsiteContentPageItemProps {
  id: string;
  title: string;
  url: string;
  lastCrawledAt: Date | string;
  status: ContentWebsitePageCrawlingStatus;
}

function getLinkPath(url: string) {
  // Extract the path from the URL
  return new URL(url).pathname;
}

export default function WebsiteContentPageItem(props: WebsiteContentPageItemProps) {
  const {
    id,
    title,
    url,
    status,
    lastCrawledAt,
  } = props;

  const request = useRequest(
    async () => api.contentWebsites.pages.refresh(id),
    {
      onSuccess: () => {
        message.success('Content refresh scheduled');
      },
      onError: () => {
        message.error('Failed to schedule content refresh');
      },
    },
  );

  return (
    <List.Item>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center flex-1">
        <div>
          <div className="fw-bold">{title}</div>
          <span className="d-flex align-items-center gap-2">
            <LinkOutlined />
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{
                maxWidth: 300,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getLinkPath(url)}
            </a>
          </span>
        </div>
        <div className="d-flex align-items-center align-items-md-end justify-content-center flex-md-column">
          <Tooltip title="Refresh Content">
            <Button
              size="small"
              loading={request.loading}
              onClick={request.submit}
              type="text"
              disabled={status === ContentWebsitePageCrawlingStatus.EXCLUDED}
            >
              <ReloadOutlined />
            </Button>
          </Tooltip>
          <div className="d-flex align-items-center gap-3 mt-1">
            {lastCrawledAt && (
              <span className="text-secondary d-flex align-items-center gap-2">
                <FlowArrowIcon />
                <span className="whitespace-nowrap">
                  {getDayTimeDisplay(new Date(lastCrawledAt))}
                </span>
              </span>
            )}
            {status && (
              <WebsiteStatusTag status={status} />
            )}
          </div>
        </div>
      </div>
    </List.Item>
  );
}
