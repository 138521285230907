import { Tag, TagProps } from 'antd';
import { FeatureRequestStatus } from '../../../api';
import { getFeatureRequestStatusLabel } from '../../../client-widgets/utils/feature-requests';

interface FeatureRequestStatusLabelProps {
  status: FeatureRequestStatus;
}

function getColor(status: FeatureRequestStatus): TagProps['color'] {
  switch (status) {
    case FeatureRequestStatus.OPEN:
      return 'blue';
    case FeatureRequestStatus.ACCEPTED:
      return 'purple';
    case FeatureRequestStatus.IN_PROGRESS:
      return 'cyan';
    case FeatureRequestStatus.IMPLEMENTED:
      return 'green';
    case FeatureRequestStatus.DECLINED:
      return 'red';
    default:
      return 'default';
  }
}

export default function FeatureRequestStatusLabel(props: FeatureRequestStatusLabelProps) {
  const { status } = props;

  return (
    <Tag color={getColor(status)}>
      {getFeatureRequestStatusLabel(status)}
    </Tag>
  );
}
