import dayjs from 'dayjs';
import CustomDateSelection, { DatePreset } from '../types/CustomDateSelection';

export function getDayDisplay(date: Date) {
  const year = date.getFullYear();
  const isSameYear = year === new Date().getFullYear();
  const monthName = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  if (isSameYear) {
    return `${monthName} ${day}`;
  }

  return `${monthName} ${day}, ${year}`;
}

export function getDayTimeDisplay(date: Date) {
  return `${getDayDisplay(date)}, ${date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
  })}`;
}

export function timeAgo(date: Date) {
  if (String(date) === 'Invalid Date') {
    return '';
  }

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }

  interval = seconds / 60;

  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }

  return `${Math.floor(seconds)} seconds ago`;
}

export function getLastYearPreset(): CustomDateSelection {
  return {
    preset: DatePreset.ONE_YEAR,
    label: '1Y',
    longLabel: 'Last year',
    from: dayjs().subtract(1, 'year'),
    to: dayjs(),
  };
}

export function getDatePresets() {
  const datePresets: CustomDateSelection[] = [
    {
      preset: DatePreset.ALL_TIME,
      label: 'All',
      longLabel: 'All time',
      from: undefined,
      to: undefined,
    },
    {
      preset: DatePreset.TODAY,
      label: 'Today',
      longLabel: 'Today',
      from: dayjs().startOf('day'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_24H,
      label: '24h',
      longLabel: 'Last 24 hours',
      from: dayjs().subtract(1, 'day'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_7D,
      label: '7d',
      longLabel: 'Last 7 days',
      from: dayjs().subtract(7, 'days'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_30D,
      label: '30d',
      longLabel: 'Last 30 days',
      from: dayjs().subtract(30, 'days'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_90D,
      label: '90d',
      longLabel: 'Last 90 days',
      from: dayjs().subtract(90, 'days'),
      to: dayjs().endOf('day'),
    },
    {
      preset: DatePreset.LAST_6M,
      label: '6m',
      longLabel: 'Last 6 months',
      from: dayjs().subtract(6, 'months'),
      to: dayjs().endOf('day'),
    },
    getLastYearPreset(),
  ];

  return datePresets;
}
