import { Block } from '@blocknote/core';
import BaseModel from './BaseModel';
import WebsiteUser from './WebsiteUser';

export enum FeatureRequestStatus {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  IN_PROGRESS = 'in-progress',
  IMPLEMENTED = 'implemented',
  DECLINED = 'declined',
}

export interface FeatureRequestData {
  /**
   * The title of the feature request
   */
  title: string;

  /**
   * Whether this is an anonymous feature request
   * Anonymous feature requests will not have the user's information,
   * but it will still be visible to the internal team
   */
  anonymous: boolean;

  /**
   * The blocks of the feature request (for the editor)
   */
  blocks: Block[];

  /**
   * The HTML of the feature request
   */
  html: string;

  /**
   * The ID of the website this feature request is for
   */
  websiteId: BaseModel['_id'];
}

export default interface FeatureRequest extends FeatureRequestData, BaseModel {
  /**
   * The status of the feature request
   */
  status: FeatureRequestStatus;

  /**
   * The number of votes the feature request has
   */
  votes: number;

  /**
   * The users who have voted for the feature request
   */
  votedBy: BaseModel['_id'][];

  /**
   * The user who created the feature request
   */
  user: Pick<WebsiteUser, '_id' | 'name' | 'avatar'>;

  /**
   * The ID of the workspace this feature request is for
   */
  workspaceId: BaseModel['_id'];

  /**
   * The number of comments the feature request has
  */
  commentsCount: number;
}

export type FeatureRequestSearchDocument = Pick<FeatureRequest, 'status' | 'title' | 'anonymous' | 'user' | 'votes' | 'commentsCount'> & {
  _id: string;
  details: string;
  workspaceId: string;
  createdAt: string;
  voted: boolean;
};
